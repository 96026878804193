import React, { memo, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import messages from "./messages";
// Firebase App (the core Firebase SDK) is always required and must be listed first
import * as firebase from "firebase/app";
// If you enabled Analytics in your project, add the Firebase SDK for Analytics
import "firebase/analytics";

import { compose } from "redux";
import Header from "../../components/Header";

import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import TextField from "@material-ui/core/TextField";

import checkSwapStyle from "./checkSwapStyle";
import Process from "../../assets/icons/process.png";
import Success from "../../assets/icons/success.png";
import { ticketService } from "../../services";
import _ from "lodash";
import Alert from "@material-ui/lab/Alert";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { confirmationSelected } from "../../actions";

function CheckSwapStatus(props) {
  const { locale } = props;
  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    firebase.analytics().setCurrentScreen("CheckSwapScreen");
    props.history.push("/");
  });

  const classes = checkSwapStyle();
  const [open, setOpen] = React.useState(false);
  const [opens, setOpens] = React.useState(false);
  const [status, setStatus] = React.useState("");
  //const [tiketid, setTiketid] = React.useState(false);
  const [openInputNotification, setOpenInputNotification] = React.useState(
    false
  );
  const handleOpen = () => {
    try {
      firebase
        .analytics()
        .logEvent("click_check_ticket", { ticket_id: status });

      ticketService
        .getTicket(status)
        .then((resp) => {
          if (resp.success === true) {
            let data = _.get(resp, "data");
            let statuss = _.get(data, "tx_status");
            // setTiketid(_.get(data, "tx_status"));
            let checkstatus = "checkstatus";
            if (statuss === "processing") {
              //สีเหลือง
              let datas = {
                status,
                checkstatus,
              };
              props.onConfirmation(datas);
              props.history.push("/showprocess");
            } else if (statuss === "completed") {
              setOpen(true);
            } else {
              setOpenInputNotification(true);
            }
          } else {
            setOpenInputNotification(true);
            console.log("resp", resp.error);
          }
          console.log("resp", resp);
        })
        .catch((error) => {
          console.log("resp", error);
        });
    } catch (error) {}
  };

  const handleClose = () => {
    setOpen(false);
    setOpens(false);
  };

  const handleChangeStatus = (value) => {
    setStatus(value);
  };

  const onCloseNotification = (value) => {
    if (value === "openInputNotification") {
      setOpenInputNotification(false);
    }
  };
  return (
    <div className={classes.root}>
      <Header locale={locale} page={"CheckSwap"} />
      <Container maxWidth="xl" className={classes.container}>
        <div className={classes.title}>
          <FormattedMessage {...messages.title} />
        </div>
        <Paper className={classes.paper}>
          {/* <div className={classes.divTopic}>
            <FormattedMessage {...messages.topic} />
          </div> */}
          <div className={classes.divText}>
            <TextField
              id="standard-basic"
              label={<FormattedMessage {...messages.topic} />}
              // placeholder="XXXXXXXX"
              style={{ width: "100%" }}
              defaultValue={status}
              onChange={(event) => {
                handleChangeStatus(event.target.value);
              }}
            ></TextField>
          </div>
          <Collapse in={openInputNotification} style={{ width: "100%" }}>
            <Alert
              severity="error"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => onCloseNotification("openInputNotification")}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              Invalid ticket id
            </Alert>
          </Collapse>
        </Paper>
        <div className={classes.divBtnCheck}>
          <Button onClick={handleOpen} className={classes.btnCheck}>
            <FormattedMessage {...messages.btnCheck} />
          </Button>
          <Modal
            aria-labelledby="title"
            aria-describedby="description"
            className={classes.modal}
            open={opens}
            onClose={handleClose}
          >
            <Fade in={opens}>
              <div className={classes.paperModal}>
                <div className={classes.divPaper}>
                  <div className={classes.divIcons}>
                    <img
                      src={Process}
                      alt="Process"
                      className={classes.processIcon}
                    ></img>
                  </div>
                  <div className={classes.divTitle}>
                    <span className={classes.title1}>
                      <FormattedMessage {...messages.titleModal} />
                    </span>
                  </div>
                  <div className={classes.divTitle2}>
                    <span className={classes.title2}>
                      <FormattedMessage {...messages.process} />
                    </span>
                  </div>
                </div>
                <div className={classes.divBtnModal}>
                  <Button onClick={handleClose} className={classes.btnOk}>
                    <FormattedMessage {...messages.btnOK} />
                  </Button>
                </div>
              </div>
            </Fade>
          </Modal>
          <Modal
            aria-labelledby="title"
            aria-describedby="description"
            className={classes.modal}
            open={open}
            onClose={handleClose}
          >
            <Fade in={open}>
              <div className={classes.paperModal}>
                <div className={classes.divPaper}>
                  <div className={classes.divIcons}>
                    <img
                      src={Success}
                      alt="Success"
                      className={classes.successIcon}
                    ></img>
                  </div>
                  <div className={classes.divTitle}>
                    <span className={classes.title1}>
                      <FormattedMessage {...messages.titleModal} />
                    </span>
                  </div>
                  <div className={classes.divTitle2}>
                    <span className={classes.title2}>
                      <FormattedMessage {...messages.complete} />
                    </span>
                  </div>
                </div>
                <div className={classes.divBtnModal}>
                  <Button onClick={handleClose} className={classes.btnOk}>
                    <FormattedMessage {...messages.btnOK} />
                  </Button>
                </div>
              </div>
            </Fade>
          </Modal>
        </div>
      </Container>
    </div>
  );
}

CheckSwapStatus.propTypes = {
  locale: PropTypes.string,
  onConfirmation: PropTypes.func,
};

const mapStateToProps = (state, ownProps) => {
  return {
    locale: state.language.locale,
    confirmation: state.confirmation.confirmation,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    //   onLocaleToggle: (key) => dispatch(changeLang(key)),
    onConfirmation: (key) => dispatch(confirmationSelected(key)),
  };
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(CheckSwapStatus);
