/*
 * Tranfer Messages
 *
 * This contains all the text for the Tranfer container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.Tranfer';

export default defineMessages({
  title1: {
    id: `${scope}.title1`,
    defaultMessage: 'Swap',
  },
  title2:{
    id: `${scope}.title2`,
    defaultMessage: 'You are swapping SIX on Stellar to this Klaytn address'
  },
  title3:{
    id: `${scope}.title3`,
    defaultMessage: 'Please transfer SIX on Stellar to:'
  },
  memotype:{
    id: `${scope}.memotype`,
    defaultMessage: 'Memo type : Text'
  },
  stellar:{
    id: `${scope}.stellar`,
    defaultMessage: 'Listed exchanges for SIX on Stellar'
  },
  klaytn:{
    id: `${scope}.klaytn`,
    defaultMessage: 'Listed exchanges for SIX on Klaytn'
  },
  remark:{
    id: `${scope}.remark`,
    defaultMessage: 'Remarks : The amount will equal to SIX '
  },
  btnNext:{
    id: `${scope}.btnNext`,
    defaultMessage: 'Next'
  },
 
});
