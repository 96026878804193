import React, { memo, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import messages from "./messages";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { compose } from "redux";
import Header from "../../components/Header";
import _ from "lodash";

// Firebase App (the core Firebase SDK) is always required and must be listed first
import * as firebase from "firebase/app";
// If you enabled Analytics in your project, add the Firebase SDK for Analytics
import "firebase/analytics";

import Container from "@material-ui/core/Container";
import { Grid, Paper } from "@material-ui/core";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";

//import Success from '../../assets/icons/success.png';
import ImgStellarktc from "../../assets/img/img_stellar_ktc.png";
import Stellar from "../../assets/icons/stellar.png";
import Klaytn from "../../assets/icons/kct.png";
import CopyIcon from "../../assets/icons/copy.png";

import Liquid from "../../assets/img/Exchange/liquid-logotype.png";
import Bitkub from "../../assets/img/Exchange/bitkub.png";
import Tokenomy from "../../assets/img/Exchange/tokenomy.png";
import CPDax from "../../assets/img/Exchange/cpdax.png";
import Bittrex from "../../assets/img/Exchange/bittrex.png";
import VccExchange from "../../assets/img/Exchange/vcc-exchange.png";
import Coinone from "../../assets/img/Exchange/coinone.png";
//import { txconfirmService } from '../../services'
import { conditionsSelected } from "../../actions";
import { tranferSelected } from "../../actions";

import tranferStyle from "./tranferStyle";

function Tranfer(props) {
  const classes = tranferStyle();

  useEffect(() => {
    firebase.analytics().setCurrentScreen("TransferInfoScreen");
    props.history.push("/");
  });
  //_.get(props.swap, 'txid')
  let kct = _.get(props.conditions, "kct");
  let txid = _.get(props.conditions, "txid");
  let mobile = _.get(props.conditions, "mobile");
  let memo = _.get(props.conditions, "memo");
  let steller = _.get(props.conditions, "steller");

  const handleChange = () => {
    firebase.analytics().logEvent("click_next_to_confirmation_screen");

    let datas = {
      txid,
      mobile,
      kct,
      memo,
      steller,
    };
    props.onTranfer(datas);
    props.history.push("/confirmation");
  };

  return (
    <div className={classes.root}>
      <Header history={props.history} />
      <Container maxWidth="xl" className={classes.container}>
        <div className={classes.divtextswap}>
          <FormattedMessage {...messages.title1} />
        </div>
        <div className={classes.divswapping}>
          <FormattedMessage {...messages.title2} />
        </div>
        <FormControl
          fullWidth
          disabled
          className={classes.margin}
          variant="outlined"
        >
          <OutlinedInput id="address" value={kct} />
        </FormControl>
        <div className={classes.divstellarktcIcon}>
          <img
            src={ImgStellarktc}
            alt="Success"
            className={classes.stellarktcIcon}
          ></img>
        </div>
        <div className={classes.divsixonstellar}>
          <FormattedMessage {...messages.title3} />
        </div>
        <FormControl
          fullWidth
          className={classes.marginInput}
          variant="outlined"
        >
          <OutlinedInput
            value={steller}
            id="steller"
            disabled
            endAdornment={
              <InputAdornment position="end">
                <CopyToClipboard text={steller}>
                  <img
                    src={CopyIcon}
                    alt="CopyIcon"
                    className={classes.btnCopy}
                  ></img>
                </CopyToClipboard>
              </InputAdornment>
            }
          />
        </FormControl>
        <div className={classes.divtextmemo}>
          <FormattedMessage {...messages.memotype} />
        </div>
        <FormControl
          fullWidth
          className={classes.marginInput}
          variant="outlined"
        >
          <OutlinedInput
            value={memo}
            id="memo"
            disabled
            endAdornment={
              <InputAdornment position="end">
                <CopyToClipboard text={memo}>
                  <img
                    src={CopyIcon}
                    alt="CopyIcon"
                    className={classes.btnCopy}
                  ></img>
                </CopyToClipboard>
              </InputAdornment>
            }
          />
        </FormControl>
        <div className={classes.divtextremark}>
          <FormattedMessage {...messages.remark} />
        </div>
        <div className={classes.divBtnNext}>
          <Button onClick={handleChange} className={classes.btnNext}>
            <FormattedMessage {...messages.btnNext} />
          </Button>
        </div>
        <Grid container>
          <Grid
            item
            lg={8}
            md={8}
            sm={8}
            xs={6}
            className={classes.gridStellar}
          >
            <div className={classes.gridItemStellar}>
              <FormattedMessage {...messages.stellar} />
              <img
                src={Stellar}
                alt="Stellar"
                className={classes.stellarIcon}
              ></img>
            </div>
            <Paper className={classes.paperStellar}>
              <Grid container className={classes.gridExchange}>
                <Grid item lg={4} md={4} sm={4} xs={4}>
                  <img
                    src={Liquid}
                    alt="Liquid"
                    className={classes.exchangeImg}
                  ></img>
                </Grid>
                <Grid item lg={4} md={4} sm={4} xs={4}>
                  <img
                    src={Bitkub}
                    alt="Bitkub"
                    className={classes.exchangeImg}
                  ></img>
                </Grid>
                <Grid item lg={4} md={4} sm={4} xs={4}>
                  <img
                    src={Tokenomy}
                    alt="Tokenomy"
                    className={classes.exchangeImg}
                  ></img>
                </Grid>
              </Grid>
              <Grid container className={classes.gridExchange}>
                <Grid item lg={4} md={4} sm={4} xs={4}>
                  <img
                    src={CPDax}
                    alt="CPDax"
                    className={classes.exchangeImg}
                  ></img>
                </Grid>
                <Grid item lg={4} md={4} sm={4} xs={4}>
                  <img
                    src={Bittrex}
                    alt="Bittrex"
                    className={classes.exchangeImg}
                  ></img>
                </Grid>
                <Grid item lg={4} md={4} sm={4} xs={4}>
                  <img
                    src={VccExchange}
                    alt="VccExchange"
                    className={classes.exchangeImg1}
                  ></img>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item lg={4} md={4} sm={4} xs={6} className={classes.gridKlaytn}>
            <div className={classes.gridItemStellar}>
              <FormattedMessage {...messages.klaytn} />
              <img
                src={Klaytn}
                alt="Klaytn"
                className={classes.klaytnIcon}
              ></img>
            </div>
            <Paper className={classes.paperKlaytn}>
              <Grid container>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <img
                    src={Coinone}
                    alt="Coinone"
                    className={classes.exchangeImg1}
                  ></img>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
Tranfer.propTypes = {
  locale: PropTypes.string,
  data: PropTypes.string,
  onSwap: PropTypes.func,
  onConditions: PropTypes.func,
  onTranfer: PropTypes.func,
};

const mapStateToProps = (state, ownProps) => {
  return {
    conditions: state.conditions.conditions,
    tranfer: state.tranfer.tranfer,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onConditions: (key) => dispatch(conditionsSelected(key)),
    onTranfer: (key) => dispatch(tranferSelected(key)),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(Tranfer);
