import { makeStyles } from "@material-ui/core/styles";
const homepageStyle = makeStyles((theme) => ({
  root: {
    backgroundImage: "linear-gradient(to right,#8E2DE2,#4A00E0)",
    flexGrow: 1,
    [theme.breakpoints.up("md")]: {
      height: "100vh",
    },
  },
  container: {
    padding: "40px 60px 60px 60px",
    [theme.breakpoints.only("xs")]: {
      padding: "40px 20px 40px 20px",
    },
    [theme.breakpoints.only("sm")]: {
      padding: "40px 40px 40px 40px",
    },
  },
  formControl: {
    width: "100px",
    "& .MuiSelect-select.MuiSelect-select": {
      textAlign: "center",
      color: "#fff",
    },
    "& .MuiInput-underline:before": {
      content: "unset",
    },
    [theme.breakpoints.between("xs", "sm")]: {
      marginBottom: "20px",
    },
  },
  divMenu: {
    "& .MuiList-padding": {
      paddingTop: "0px",
      paddingBottom: "0px",
    },
    "& .MuiInput-underline:before": {
      content: "unset",
    },
    "& .MuiSelect-select.MuiSelect-select": {
      alignItems: "center",
      display: "flex",
      fontSize: "14px",
      color: "#fff",
    },
  },
  flagIcons: {
    width: "28px",
    height: "18px",
    paddingRight: "4px",
  },
  gridCon: {
    justifyContent: "space-between",
  },
  divLogo: {
    textAlign: "center",
    paddingTop: "80px",
    [theme.breakpoints.between("xs", "sm")]: {
      paddingTop: "40px",
    },
  },
  logo: {
    width: "55%",
    [theme.breakpoints.only("xs")]: {
      width: "85%",
    },
  },
  gridRight: {
    borderRight: "1px solid #fff",
    [theme.breakpoints.between("xs", "sm")]: {
      borderRight: "unset",
    },
  },
  paper: {
    padding: "30px",
    marginRight: "60px",
    [theme.breakpoints.only("xs")]: {
      padding: "10px",
      marginRight: "0px",
    },
    [theme.breakpoints.only("sm")]: {
      marginRight: "0px",
    },
  },
  title: {
    fontSize: "18px",
    fontWeight: "bold",
  },
  divImage: {
    textAlign: "center",
    padding: "50px 30px 30px",
  },
  image: {
    width: "80%",
  },
  list: {
    paddingTop: "24px",
  },
  divBtn: {
    textAlign: "center",
    width: "100%",
  },
  btnSwap: {
    backgroundColor: "#FFD76A",
    color: "#484848",
    width: "55%",
    fontSize: "24px",
    fontWeight: "bold",
    borderRadius: "35px",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "#FFD76A",
    },
    [theme.breakpoints.only("xs")]: {
      width: "100%",
      fontSize: "20px",
    },
    [theme.breakpoints.only("md")]: {
      width: "75%",
      fontSize: "20px",
    },
  },
  btnCheck: {
    backgroundColor: "#FFD76A",
    color: "#484848",
    width: "50%",
    fontSize: "24px",
    fontWeight: "bold",
    borderRadius: "35px",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "#FFD76A",
    },
    [theme.breakpoints.only("xs")]: {
      width: "80%",
      fontSize: "20px",
    },
    [theme.breakpoints.only("md")]: {
      width: "65%",
      fontSize: "20px",
    },
  },
  divBtnModal: {
    textAlign: "center",
    paddingTop: "30px",
    [theme.breakpoints.only("xs")]: {
      paddingBottom: "30px",
    },
  },
  btnBack: {
    border: "1px solid #4A00E0",
    color: "#484848",
    width: "40%",
    marginRight: "30px",
    fontSize: "20px",
    fontWeight: "bold",
    borderRadius: "35px",
    textTransform: "capitalize",
    [theme.breakpoints.only("xs")]: {
      width: "80%",
      fontSize: "20px",
      marginRight: "0px",
      marginBottom: "10px",
    },
  },
  btnDone: {
    backgroundColor: "#FFD76A",
    color: "#484848",
    width: "40%",
    fontSize: "20px",
    fontWeight: "bold",
    borderRadius: "35px",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "#FFD76A",
    },
    [theme.breakpoints.only("xs")]: {
      width: "80%",
      fontSize: "20px",
    },
  },
  divSix: {
    textAlign: "center",
    paddingTop: "175px",
    [theme.breakpoints.between("xs", "sm")]: {
      paddingTop: "140px",
    },
  },
  sixImage: {
    width: "30%",
    [theme.breakpoints.only("xs")]: {
      width: "40%",
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paperModal: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: "6px",
    boxShadow: theme.shadows[5],
    padding: "30px 40px",
    width: "50%",
    textAlign: "center",
    [theme.breakpoints.only("xs")]: {
      width: "100%",
      padding: "20px 20px 0px",
      margin: "30px",
      height: "80%",
      overflowY: "auto",
    },
  },
  paperModalTobridge: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: "6px",
    boxShadow: theme.shadows[5],
    padding: "30px 40px",
    width: "30%",
    textAlign: "center",
    [theme.breakpoints.only("xs")]: {
      width: "100%",
      padding: "20px 20px 0px",
      margin: "30px",
      height: "80%",
      overflowY: "auto",
    },
  },
  titleModal: {
    fontSize: "20px",
    fontWeight: "bold",
    color: "#484848",
  },
  title1: {
    fontWeight: "bold",
    color: "#484848",
    paddingLeft: "20px",
  },
  title2: {
    fontWeight: "bold",
    color: "#8E2DE2",
    [theme.breakpoints.only("md")]: {
      fontSize: "15px",
    },
  },
  divImageNote: {
    textAlign: "center",
  },
  imporNote: {
    width: "50%",
    paddingBottom: "30px",
  },
  closeIcon: {
    textAlign: "end",
    cursor: "pointer",
  },
}));
export default homepageStyle;
