import { makeStyles } from '@material-ui/core/styles';
const conditionsStyle = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: '#F5F5F8',
        // height: '100vh',
        [theme.breakpoints.between('xs', 'md')]: {
            height: '100vh',
        },
    },
    container: {
        padding: "100px 60px 60px 60px",
        [theme.breakpoints.only('xs')]: {
            padding: "100px 20px 40px 20px",
        },
        [theme.breakpoints.only('sm')]: {
            padding: "120px 40px 40px 40px",
        },
    },
    title: {
        fontSize: '30px',
        color: '#7D7D7D'
    },
    paper: {
        paddingTop: '40px',
        textAlign: "center",
        margin: "0px 400px",
        borderTop: "6px solid #EBBE44",
        borderRadius: '2px',
        padding: '15px',
        [theme.breakpoints.only('md')]: {
            margin: "0px 200px",
        },
        [theme.breakpoints.only('sm')]: {
            margin: "0px 150px",
        },
        [theme.breakpoints.only('xs')]: {
            margin: "0px",
        },
    },
    divTopic: {
        padding: '15px',
        color: '#7D7D7D',
    },
    divText: {
        border: '1px solid red',
        padding: '5px 15px 15px',
        '& .MuiInput-underline:before': {
            content: 'none'
        },
        '& .MuiInput-underline:after': {
            borderBottom: 'unset'
        }
    },
    divBtnNext: {
        textAlign: 'center',
        padding: '50px 0px'
    },
    btnNext: {
        backgroundColor: "#4A00E0",
        color: '#ffffff',
        width: '25%',
        fontSize: '20px',
        fontWeight: 'bold',
        borderRadius: '35px',
        textTransform: 'capitalize',
        '&:hover': {
            backgroundColor: "#4A00E0",
        },
        [theme.breakpoints.only('xs')]: {
            width: '80%',
        },
        [theme.breakpoints.only('sm')]: {
            width: '35%',
        },
        [theme.breakpoints.only('md')]: {
            width: '40%',
        },
    },
    divVerify: {
        padding: '5px 15px 15px',
        color: '#484848',
        fontSize: '22px',
        fontWeight: 'bold',
        textAlign: 'center'
    },
    divStyle: {
        alignContent: 'center',
        marginTop: '20px'
    },
    divTryagain: {
        textAlign: 'center',
        paddingTop: '20px'
    },
    divSms: {
        fontSize: '20px',
        color: '#ffffff'
    },
    divTry: {
        fontSize: '20px',
        color: '#FFD76A'
    },
    textField: {
        backgroundColor: '#fff',
        margin: '18px 0px',
        '& .MuiOutlinedInput-inputMarginDense': {
            padding: '15px 10px'
        },
        '&:hover': {
            border: 'unset'
        },
        '& .MuiOutlinedInput:focus': {
            outline: 0
        },
    },
    divIcons: {
        textAlign: 'center'
    },
    inputProps: {
        step: 300,
    },
    pasteSection: {
        // height: '400px',
        overflow: 'auto',
        marginTop: '18px'
    },
    pasteTextField: {
        width: '100%',
        minHeight: '325px',
        backgroundColor: '#BABABA15',
        borderColor: '#7D7D7D15'
    },
    divAccept: {
        fontSize: '18px',
        color: '#7D7D7D',
        [theme.breakpoints.only('xs')]: {
            fontSize: '14px'
        },
    },
    divTerm: {
        fontSize: '18px',
        color: '#7D7D7D',
        fontWeight: 'bold',
        [theme.breakpoints.only('xs')]: {
            fontSize: '14px'
        },
    },
    radioStyle: {
        marginTop: '10px',
        color: '#7D7D7D',
        '& .MuiFormControlLabel-root': {
            marginRight: '10px',
            [theme.breakpoints.only('xs')]: {
                marginRight: '0px',
            },
        },
        '& .MuiRadio-colorSecondary.Mui-checked': {
            color: '#7D7D7D'
        },
        '&:hover': {
            color: '#7D7D7D'
        },
        [theme.breakpoints.only('xs')]: {
            marginTop: '0px',
        },
    },
    margin: {
        margin: '20px 0px',
        backgroundColor: '#fff',
        border: 'unset',
        '& .Mui-focused': {
            border: 'unset'
        },
        '& .MuiOutlinedInput-input': {
            padding: '14px',
            [theme.breakpoints.only('xs')]: {
                padding: '10px',
            },
        },
        '& .MuiOutlinedInput-input:focus': {
            outline: 0
        }
    },
    successIcon: {
        width: '30px',
        [theme.breakpoints.only('md')]: {
            width: '25px',
        },
        [theme.breakpoints.only('sm')]: {
            width: '25px',
        },
        [theme.breakpoints.only('xs')]: {
            width: '20px',
        },
    },
    title2: {
        fontSize: '20px',
        color: '#7D7D7D',
        marginTop: '40px',
        [theme.breakpoints.only('xs')]: {
            marginTop: '20px',
            fontSize: '16px',
        },
    },
    title3: {
        fontSize: '20px',
        color: '#7D7D7D',
        marginTop: '30px',
        fontWeight: 'bold',
        [theme.breakpoints.only('xs')]: {
            marginTop: '20px',
            fontSize: '16px',
        },
    },
    borderBox: {
        border: '1px solid #7d7d7d',
        borderRadius: '4px',
        backgroundColor: '#EDEDEF',
        padding: '0px 20px 20px',
        overflowY: 'auto',
        height: '250px',
        margin: '20px 0px 10px'
    },
    termsAndCondTitle: {
        fontSize: 14,
        color: '#4449A8',
        fontWeight: 'bold',
        lineHeight: 1,
        display: 'block',
        margin: '20px 0px 10px'
    },
    termsAndCondText: {
        fontSize: 12,
        color: '#7D7D7D',
        lineHeight: 1,
    },
}))

export default conditionsStyle