/*
 * Swap Messages
 *
 * This contains all the text for the Swap container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.Swap';

export default defineMessages({
  title1: {
    id: `${scope}.title1`,
    defaultMessage: 'Swap',
  },
  title2:{
    id: `${scope}.title2`,
    defaultMessage: 'Please enter your Klaytn wallet address'
  },
  btnVerify:{
    id: `${scope}.btnVerify`,
    defaultMessage: 'Verify'
  },
  howto:{
    id: `${scope}.howto`,
    defaultMessage: 'How to create Klaytn wallet'
  },
  warning:{
    id: `${scope}.warning`,
    defaultMessage: 'Warning'
  },
  detailWarning1:{
    id: `${scope}.detailWarning1`,
    defaultMessage: 'Both Klaytn wallet and Klip wallet are for Klay and KCT tokens. Please make sure you have the private key to access this wallet address or else you will lose all of your SIX-KCT tokens.'
  },
  detailWarning2:{
    id: `${scope}.detailWarning2`,
    defaultMessage: 'Please note that SIX Network is not responsible for the loss of your private key and the SIX-KCT tokens.'
  },
  pleasetype:{
    id: `${scope}.pleasetype`,
    defaultMessage: 'Please type your'
  },
  lastdigit:{
    id: `${scope}.lastdigit`,
    defaultMessage: 'last 4-digit of your Klaytn address'
  },
  alert:{
    id: `${scope}.alert`,
    defaultMessage: "Klaytn wallet address Incorrect",
  },

 
});
