import { conditionsConstants } from '../constants'


export const conditionsSelected = (conditions) => {
    return async (dispatch) => {
        dispatch({ type : conditionsConstants.CONDITIONS_SELECTED, conditions})
    }
}

// export const informationActions = {
//      informationSelected
// }