/*
 * CheckSwapStatus Messages
 *
 * This contains all the text for the CheckSwapStatus container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.CheckSwapStatus';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Please enter your ticket ID',
  },
  topic:{
    id: `${scope}.topic`,
    defaultMessage: 'Ticket ID'
  },
  btnCheck:{
    id: `${scope}.btnCheck`,
    defaultMessage: 'Check'
  },
  titleModal: {
    id: `${scope}.titleModal`,
    defaultMessage: 'Your token swap is',
  },
  process:{
    id: `${scope}.process`,
    defaultMessage: 'On Process'
  },
  complete:{
    id: `${scope}.complete`,
    defaultMessage: 'Completed'
  },
  btnOK:{
    id: `${scope}.btnOK`,
    defaultMessage: 'OK'
  }
 
});
