import { informationConstants } from '../constants'

const defaultState = {
    data: [{}],
  }

export default function information(state = defaultState, action){
    switch(action.type) {
        case informationConstants.INFORMATION_SELECTED:
            return { ...state, ...action }
        default:
            return { ...state }
    }
}