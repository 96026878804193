/*
 * Information Messages
 *
 * This contains all the text for the Information container.
 */

import { defineMessages } from "react-intl";

export const scope = "app.containers.Information";

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: "Please enter your information",
  },
  name: {
    id: `${scope}.name`,
    defaultMessage: "Name",
  },
  lastname: {
    id: `${scope}.lastname`,
    defaultMessage: "Lastname",
  },
  mobilenumber: {
    id: `${scope}.mobilenumber`,
    defaultMessage: "Mobile Number",
  },
  inputmobilenumber: {
    id: `${scope}.inputmobilenumber`,
    defaultMessage: "Enter your mobile phone",
  },
  eng: {
    id: `${scope}.eng`,
    defaultMessage: 'English',
  },
  korea: {
    id: `${scope}.korea`,
    defaultMessage: '한글',
  },
  alert: {
    id: `${scope}.alert`,
    defaultMessage: "Please specify complete information.",
  },
  btnNext: {
    id: `${scope}.btnNext`,
    defaultMessage: "Next",
  },
});
