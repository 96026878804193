import React, { memo, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import messages from "./messages";
import { verifyService } from "../../services";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// import { informationSelected } from "../../actions";
import { verifySelected } from "../../actions";
//import * as moment from 'moment'
import _ from "lodash";

// Firebase App (the core Firebase SDK) is always required and must be listed first
import * as firebase from "firebase/app";
// If you enabled Analytics in your project, add the Firebase SDK for Analytics
import "firebase/analytics";

import { compose } from "redux";
import Header from "../../components/Header";
//import { Link } from 'react-router-dom'
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";

import verifyStyle from "./verifyStyle";
import PinInput from "react-pin-input";
//import { from } from "rxjs"
import { Button, TextField } from "@material-ui/core";
import { issueService } from "../../services";
// import { informationActions } from "../../actions";

function Verify(props) {
  const classes = verifyStyle();
  //const [value, setValue] = React.useState('');
  const [counter, setCounter] = React.useState(60);
  //const [test, setTest] = React.useState(counter > 0 && setTimeout(() => setCounter(counter - 1), 1000))
  const [resend, setResend] = React.useState(false);
  const [colors, setColors] = React.useState(true);
  const [ref_n, setRef] = React.useState("");
  const [refnew, setRefNew] = React.useState("");
  const [tx, setTx] = React.useState("");
  let timer;

  const tryagain = () => {
    //รับ
    let mobile = _.get(props.information, "mobiles");
    let first = _.get(props.information, "name");
    let last = _.get(props.information, "lastname");

    try {
      firebase.analytics().logEvent("click_resend_otp");

      issueService
        .updateData("resend", "Verify phone number", mobile, first, last)
        .then((resp) => {
          if (resp.success === true) {
            let data = _.get(resp, "data");
            let ref_no = _.get(data, "ref_no");
            let tx_id = _.get(data, "tx_id");
            setRef(ref_no);
            setRefNew(ref_no);
            setResend(false);
            setCounter(60);
            setTx(tx_id);
          } else {
            console.log("resp", resp);
          }
        })
        .catch((error) => {
          console.log("resp", error);
        });
    } catch (error) {}
  };

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    firebase.analytics().setCurrentScreen("VerifyScreen");
    props.history.push("/");
  });

  useEffect(() => {
    timer = counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
    if (counter === 0) {
      setResend(true);
      return () => clearInterval(timer);
    }
    setRef(_.get(props.information, "ref_no"));
  }, [counter]);

  const handleChange = (event) => {
    setColors(true);
    let txid = _.get(props.information, "tx_id");
    let mobile = _.get(props.information, "mobiles");
    let ref = _.get(props.information, "ref_no");
    if (event.length === 6) {
      firebase.analytics().logEvent("confirm_otp_input");

      if (tx !== "") {
        try {
          verifyService
            .updateVerify(tx, "verify", mobile, event, refnew)
            .then((resp) => {
              if (resp.success === true) {
                firebase
                  .analytics()
                  .logEvent("confirm_otp_response", { success: true });

                let datas = {
                  tx,
                  mobile,
                };

                props.onVerify(datas);
                props.history.push("/swap");
                console.log("resp", resp);
              } else {
                firebase
                  .analytics()
                  .logEvent("confirm_otp_response", { success: false });

                setColors(false);
                console.log("resp", resp.error);
              }
            })
            .catch((error) => {
              console.log("resp", error);
            });
        } catch (error) {}
      } else {
        try {
          verifyService
            .updateVerify(txid, "verify", mobile, event, ref)
            .then((resp) => {
              firebase
                .analytics()
                .logEvent("confirm_otp_response", { success: resp.success });

              if (resp.success === true) {
                let datas = {
                  txid,
                  mobile,
                };

                props.onVerify(datas);
                props.history.push("/swap");
                console.log("resp", resp);
              } else {
                setColors(false);
                console.log("resp", resp.error);
              }
            })
            .catch((error) => {
              console.log("resp", error);
            });
        } catch (error) {}
      }
    }
  };

  // change code below this line
  // let ref = props.match.params.action

  return (
    <div className={classes.root}>
      <Header history={props.history} />
      <Container maxWidth="xl" className={classes.container}>
        <div className={classes.title}>
          <FormattedMessage {...messages.title} />
        </div>
        <Paper className={classes.paper}>
          <PinInput
            length={6}
            initialValue=""
            onChange={(value, index) => {
              handleChange(value);
            }}
            type="numeric"
            focus={true}
            // style={{width:'max-content'}}
            inputStyle={{
              borderBottomColor: "#7D7D7D",
              borderLeftColor: "#ffffff",
              borderRightColor: "#ffffff",
              borderTopColor: "#ffffff",
              borderWidth: 1,
              color: colors ? "#000000" : "red",
              fontWeight: "bold",
              fontSize: 20,
            }}
            inputFocusStyle={{
              borderLeftColor: "#ffffff",
              borderRightColor: "#ffffff",
              borderBottomColor: "#7D7D7D",
            }}
          />
          {refnew === "" ? (
            <div className={classes.divStyle}>
              <span className={classes.divText}>
                <FormattedMessage {...messages.refno} />
              </span>
              <span className={classes.divVerify}>{ref_n}</span>
            </div>
          ) : (
            <div className={classes.divStyle}>
              <span className={classes.divText}>
                <FormattedMessage {...messages.refno} />
              </span>
              <span className={classes.divVerify}>{refnew}</span>
            </div>
          )}
        </Paper>
        <div className={classes.divTryagain}>
          <div className={classes.divSms}>
            <FormattedMessage {...messages.sms} />
            &nbsp;&nbsp;&nbsp;
            {resend ? (
              <Button className={classes.divTry} onClick={tryagain}>
                &nbsp;{" "}
                <span>
                  <FormattedMessage {...messages.tryagain} />
                </span>
              </Button>
            ) : (
              <TextField
                style={{ textDecorationLine: "unset" }}
                value={counter}
              ></TextField>
            )}{" "}
          </div>
          {/* <span className={classes.divSms}>Didn’t receive an SMS?</span> */}
        </div>
      </Container>
    </div>
  );
}

Verify.prototypes = {
  locale: PropTypes.string,
  data: PropTypes.string,
  onData: PropTypes.func,
  onVerify: PropTypes.func,
};

const mapStateToProps = (state, ownProps) => {
  return {
    locale: state.language.locale,
    information: state.information.information,
    verify: state.verify.verify,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    // informationSelected: information => {
    //   dispatch(informationActions.informationSelected(information))
    // }
    //onData: (key) => dispatch(informationSelected(key)),
    onVerify: (key) => dispatch(verifySelected(key)),
  };
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect, memo)(Verify);
