/*
 * ShowProcess Messages
 *
 * This contains all the text for the ShowProcess container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.ShowProcess';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Your token swap is on process.',
  },
  ticketID: {
    id: `${scope}.ticketID`,
    defaultMessage: 'Ticket ID :'
  },
  warning: {
    id: `${scope}.warning`,
    defaultMessage: 'Please be sure to transfer the amount of SIX-Stellar you would like to swap to the address with the correct memo.'
  },
  name: {
    id: `${scope}.name`,
    defaultMessage: 'Name-Surname'
  },
  to: {
    id: `${scope}.to`,
    defaultMessage: 'To'
  },
  memotype: {
    id: `${scope}.memotype`,
    defaultMessage: 'Memo type : Text'
  },
  date: {
    id: `${scope}.date`,
    defaultMessage: 'Date'
  },
  time: {
    id: `${scope}.time`,
    defaultMessage: 'Time'
  },
  btnHome: {
    id: `${scope}.btnHome`,
    defaultMessage: 'Home'
  },

});
