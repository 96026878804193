import { swapConstants } from '../constants'

const defaultState = {
    data: [{}],
  }

export default function swap(state = defaultState, action){
    switch(action.type) {
        case swapConstants.SWAP_SELECTED:
            return { ...state, ...action }
        default:
            return { ...state }
    }
}