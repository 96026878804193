import React, { memo, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import messages from "./messages";

// Firebase App (the core Firebase SDK) is always required and must be listed first
import * as firebase from "firebase/app";
// If you enabled Analytics in your project, add the Firebase SDK for Analytics
import "firebase/analytics";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import Header from "../../components/Header";
import FormControl from "@material-ui/core/FormControl";
import Container from "@material-ui/core/Container";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Button from "@material-ui/core/Button";

import confirmationStyle from "./confirmationStyle";

import Success from "../../assets/icons/success.png";
import { txconfirmService } from "../../services";
import _ from "lodash";
import { verifySelected } from "../../actions";
import { confirmationSelected } from "../../actions";

function Confirmation(props) {
  const classes = confirmationStyle();
  useEffect(() => {
    console.log("confirm", props);
    firebase.analytics().setCurrentScreen("ConfirmationScreen");
    props.history.push("/");
  });

  let memos = _.get(props.tranfer, "memo");
  let steller = _.get(props.tranfer, "steller");

  const handleChange = () => {
    let txid = _.get(props.tranfer, "txid");
    let mobile = _.get(props.tranfer, "mobile");
    try {
      firebase.analytics().logEvent("click_confirm_swap_btn");

      txconfirmService
        .updateTxConfirm(txid, mobile)
        .then((resp) => {
          if (resp.success === true) {
            const data = _.get(resp, "data");
            let ticketid = _.get(data, "ticket_id");
            let datas = {
              ticketid,
            };
            props.onConfirmation(datas);
            props.history.push("/showprocess");
            console.log("resp", resp);
          } else {
            console.log("resp", resp.error);
          }
        })
        .catch((error) => {
          console.log("resp", error);
        });
    } catch (error) {}
  };

  const [setStellar] = React.useState("");
  const [setMemo] = React.useState("");

  const handleChangeStellar = (value) => {
    setStellar(value);
  };

  const handleChangeMemo = (value) => {
    setMemo(value);
  };

  return (
    <div className={classes.root}>
      <Header history={props.history} />
      <Container maxWidth="xl" className={classes.container}>
        <div className={classes.divtextconfirmation}>
          <FormattedMessage {...messages.title1} />
        </div>
        <div className={classes.divsixonstellar}>
          <FormattedMessage {...messages.title2} />
        </div>
        <div>
          <TextField
            id="outlined-search"
            margin="dense"
            variant="outlined"
            fullWidth
            className={classes.textField}
            value={steller}
            disabled
            onChange={(event) => {
              handleChangeStellar(event.target.value);
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton aria-label="id" edge="end">
                  <CheckCircleIcon />
                </IconButton>
              </InputAdornment>
            }
          >
            <img
              src={Success}
              alt="Success"
              className={classes.successIcon}
            ></img>
          </TextField>
        </div>
        <div className={classes.divtextmemo}>
          <FormattedMessage {...messages.memotype} />
        </div>
        <FormControl
          fullWidth
          className={classes.marginInput}
          variant="outlined"
        >
          <TextField
            id="memo"
            margin="dense"
            variant="outlined"
            fullWidth
            className={classes.textField}
            value={memos}
            disabled
            onChange={(event) => {
              handleChangeMemo(event.target.value);
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton aria-label="id" edge="end">
                  <CheckCircleIcon />
                </IconButton>
              </InputAdornment>
            }
          >
            <img
              src={Success}
              alt="Success"
              className={classes.successIcon}
            ></img>
          </TextField>
        </FormControl>
        <div className={classes.divtextmemo}>
          <FormattedMessage {...messages.remark} />
        </div>
        <div className={classes.divBtnNext}>
          <Button onClick={handleChange} className={classes.btnNext}>
            <FormattedMessage {...messages.btnConfirm} />
          </Button>
        </div>
      </Container>
    </div>
  );
}

Confirmation.prototypes = {
  locale: PropTypes.string,
  data: PropTypes.string,
  onData: PropTypes.func,
  onVerify: PropTypes.func,
  onConfirmation: PropTypes.func,
};

const mapStateToProps = (state, ownProps) => {
  return {
    locale: state.language.locale,
    information: state.information.information,
    verify: state.verify.verify,
    tranfer: state.tranfer.tranfer,
    confirmation: state.confirmation.confirmation,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    // informationSelected: information => {
    //   dispatch(informationActions.informationSelected(information))
    // }
    //onData: (key) => dispatch(informationSelected(key)),
    onVerify: (key) => dispatch(verifySelected(key)),
    onConfirmation: (key) => dispatch(confirmationSelected(key)),
  };
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(Confirmation);
