import { confirmationConstants } from '../constants'


export const confirmationSelected = (confirmation) => {
    return async (dispatch) => {
        dispatch({ type : confirmationConstants.CONFIRMATION_SELECTED, confirmation})
    }
}

// export const informationActions = {
//      informationSelected
// }