/*
 * Conditions Messages
 *
 * This contains all the text for the Conditions container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.Conditions';

export default defineMessages({
  title1: {
    id: `${scope}.title1`,
    defaultMessage: 'Swap',
  },
  title2:{
    id: `${scope}.title2`,
    defaultMessage: 'Klaytn wallet address'
  },
  termofcondition:{
    id: `${scope}.termofcondition`,
    defaultMessage: 'Terms and Conditions'
  },
  accept:{
    id: `${scope}.accept`,
    defaultMessage: 'I accept this'
  },
  surethat:{
    id: `${scope}.surethat`,
    defaultMessage: 'I’m sure that I have this'
  },
  klaytnwallet:{
    id: `${scope}.klaytnwallet`,
    defaultMessage: 'Klaytn wallet’s private key.'
  },
  btnNext:{
    id: `${scope}.btnNext`,
    defaultMessage: 'Next'
  },
  
 
});
