import { makeStyles } from '@material-ui/core/styles';
const checkSwapStyle = makeStyles(theme => ({
    root:{
        backgroundImage: "linear-gradient(to right,#8E2DE2,#4A00E0)",
        flexGrow:1,
        height:'100vh',
    },
    container:{
        padding:"140px 60px 60px 60px",
        [theme.breakpoints.only('xs')]: {
            padding:"100px 20px 40px 20px",
        },
        [theme.breakpoints.only('sm')]: {
            padding:"140px 40px 40px 40px",
        },
    },
    title:{
        color:'#fff',
        fontSize:'24px',
        textAlign:'center',
        padding:'50px 100px',
        [theme.breakpoints.only('xs')]: {
            padding:'50px 0px',
        },
    },
    paper:{
        margin:"0px 400px",
        borderTop:"6px solid #EBBE44",
        borderRadius:'2px',
        padding:'15px',
        [theme.breakpoints.only('md')]: {
            margin:"0px 200px",
        },
        [theme.breakpoints.only('sm')]: {
            margin:"0px 150px",
        },
        [theme.breakpoints.only('xs')]: {
            margin:"0px",
        },
    },
    divTopic:{
        padding:'15px',
        color:'#7D7D7D',
    },
    divText:{
        padding:'5px 15px 15px',
        '& .MuiInput-underline:before':{
            content:'none'
        },
        '& .MuiInput-underline:after':{
            borderBottom:'unset'
        }
    },
    textStyle:{
        width: '100%'
    },
    divBtnCheck:{
        textAlign:'center',
        padding:'50px 0px'
    },
    btnCheck:{
        backgroundColor:"#FFD76A",
        color:'#484848',
        width:'25%',
        fontSize:'20px',
        fontWeight:'bold',
        borderRadius:'35px',
        textTransform:'capitalize',
        '&:hover':{
            backgroundColor:"#FFD76A",
        },
        [theme.breakpoints.only('xs')]: {
            width:'80%',
        },
        [theme.breakpoints.only('sm')]: {
            width:'35%',     
        },
        [theme.breakpoints.only('md')]: {
            width:'40%',     
        },
    },
    divBtnModal:{
        textAlign:'center',
        paddingTop:'30px'
    },
    btnOk:{
        backgroundColor:"#FFD76A",
        color:'#484848',
        width:'40%',
        fontSize:'20px',
        fontWeight:'bold',
        borderRadius:'35px',
        textTransform:'capitalize',
        '&:hover':{
            backgroundColor:"#FFD76A",
        },
        [theme.breakpoints.only('xs')]: {
            width:'80%',
            fontSize:'20px',
        },
        [theme.breakpoints.only('sm')]: {
            width:'60%',
            fontSize:'20px',
        },
       
    },
    divSix:{
        textAlign:'center',
        paddingTop:'175px',
        [theme.breakpoints.between('xs','sm')]: {
            paddingTop:'140px',
        },
    },
    sixImage:{
        width:'30%',
        [theme.breakpoints.only('xs')]: {
            width:'40%',
        },
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    divPaper:{
        borderRadius:'6px',
        backgroundColor:'#fff'
    },
    paperModal: {
        backgroundColor: '#fffffff',
        width:'40%',
        border:'unset',
        outline: 0,
        [theme.breakpoints.only('xs')]: {
            width:'80%',
        },
        [theme.breakpoints.only('sm')]: {
            width:'51%',
        },
        [theme.breakpoints.only('md')]: {
            width:'50%',
        },
    },
    divTitle:{
        textAlign:'center',
        paddingBottom:'15px'
    },
    divTitle2:{
        textAlign:'center',
        paddingBottom:'35px'
    },
    title1:{
        fontSize:'18px',
        color:'#484848',
    },
    title2:{
        fontSize:'22px',
        fontWeight:'bold',
        color:'#484848',
    },
    divIcons:{
        textAlign:'center'
    },
    processIcon:{
        width:'25%',
        paddingTop:'35px',
        paddingBottom:'35px',
        [theme.breakpoints.only('sm')]: {
            paddingTop:'30px',
            paddingBottom:'30px',
        },
        [theme.breakpoints.only('xs')]: {
            paddingTop:'30px',
            paddingBottom:'30px',
        },
    },
    successIcon:{
        width:'25%',
        paddingTop:'35px',
        paddingBottom:'35px',
        [theme.breakpoints.only('sm')]: {
            paddingTop:'30px',
            paddingBottom:'30px',
        },
        [theme.breakpoints.only('xs')]: {
            paddingTop:'30px',
            paddingBottom:'30px',
        },
    },
}))

export default checkSwapStyle
