import { API } from 'aws-amplify'
// import moment from 'moment'

const updateTxConfirm = (
    tx_id,
    phone_number,
) => {
    return API.put('*', '/tx/confirm', {
        // headers: {
        //     'x-six-authorization': token,
        // },
        body: {
            tx_id: tx_id,
            phone_number: phone_number,
        },
    })
}

export const txconfirmService = {
    updateTxConfirm,
}
