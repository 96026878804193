import { makeStyles } from '@material-ui/core/styles';
const verifyStyle = makeStyles(theme => ({
    root: {
        backgroundImage: "linear-gradient(to right, #8E2DE2,#4A00E0)",
        flexGrow: 1,
        [theme.breakpoints.between('xs', 'lg')]: {
            height: '100vh',
        },
    },
    container: {
        padding: "100px 60px 60px 60px",
        [theme.breakpoints.only('xs')]: {
            padding: "100px 20px 40px 20px",
        },
        [theme.breakpoints.only('sm')]: {
            padding: "100px 40px 40px 40px",
        },
        [theme.breakpoints.only('md')]: {
            padding: "140px 40px 40px 40px",
        },
    },
    title: {
        color: '#fff',
        fontSize: '24px',
        textAlign: 'center',
        padding: '50px 100px',
        [theme.breakpoints.only('xs')]: {
            padding: '30px 0px',
            fontSize: '20px',
        },
    },
    paper: {
        padding: '40px 15px',
        textAlign: "center",
        margin: "0px 400px",
        borderTop: "6px solid #EBBE44",
        borderRadius: '4px',
        [theme.breakpoints.only('md')]: {
            margin: "0px 260px",
        },
        [theme.breakpoints.only('sm')]: {
            margin: "0px 150px",
            padding: '20px 0px 20px',
        },
        [theme.breakpoints.only('xs')]: {
            margin: "0px",
            padding: '20px 0px 0px',
        },
        '& .pincode-input-container': {
            '& .pincode-input-text': {
                height: '40px',
                width: '40px'
            }
        }
    },
    divTopic: {
        padding: '15px',
        color: '#7D7D7D',
    },
    divText: {
        padding: '5px 10px 15px',
        color: '#7D7D7D',
        fontSize: '22px',
        textAlign: 'center',
        [theme.breakpoints.down('md')]: {
            padding: '5px 5px 15px',
        },
    },
    divBtnNext: {
        textAlign: 'center',
        padding: '50px 0px'
    },
    btnNext: {
        backgroundColor: "#FFD76A",
        color: '#484848',
        width: '25%',
        fontSize: '20px',
        fontWeight: 'bold',
        borderRadius: '35px',
        textTransform: 'capitalize',
        '&:hover': {
            backgroundColor: "#FFD76A",
        },
        [theme.breakpoints.only('xs')]: {
            width: '80%',
        },
        [theme.breakpoints.only('sm')]: {
            width: '35%',
        },
        [theme.breakpoints.only('md')]: {
            width: '40%',
        },
    },
    divVerify: {
        padding: '5px 0px 15px',
        color: '#484848',
        fontSize: '22px',
        fontWeight: 'bold',
        textAlign: 'center',
        [theme.breakpoints.down('md')]: {
            padding: '5px 0px 15px',
        },
    },
    divStyle: {
        alignContent: 'center',
        padding: '30px 0px 0px',
        [theme.breakpoints.only('md')]: {
            padding: '20px 0px 10px',
        },
        [theme.breakpoints.only('sm')]: {
            padding: '20px 0px 0px',
        },
        [theme.breakpoints.only('xs')]: {
            padding: '20px 0px 10px',
        },
    },
    divTryagain: {
        textAlign: 'center',
        paddingTop: '20px',
        '& .MuiInput-underline:before':{
            content:'unset',
        },
        '& .MuiInputBase-input':{
            fontSize:'20px',
            color:'#fff',
            width:'30px'
        }
    },
    divSms: {
        fontSize: '20px',
        color: '#ffffff',
        alignItems:'center',
        display:'inline-flex'
    },
    divTry: {
        fontSize: '18px',
        color: '#FFD76A',
        textTransform:'capitalize'   ,
        '& .MuiButton-text':{
            padding:'0px'
        } 
    }
}))

export default verifyStyle