import { makeStyles } from '@material-ui/core/styles';
const showProcessStyle = makeStyles(theme => ({
    root: {
        backgroundImage: "linear-gradient(to right,#8E2DE2,#4A00E0)",
        flexGrow: 1,
        // height: '100vh',
        [theme.breakpoints.between('sm', 'md')]: {
            height: '100vh',
        },

    },
    container: {
        padding: "130px 60px 0px 60px",
        [theme.breakpoints.only('xs')]: {
            padding: "130px 20px 0px 20px",
        },
        [theme.breakpoints.only('sm')]: {
            padding: "140px 40px 40px 40px",
        },
        [theme.breakpoints.only('md')]: {
            padding: "140px 40px 40px 40px",
        },
    },
    paper: {
        margin: "0px 330px",
        borderRadius: '2px',
        padding: '15px',
        [theme.breakpoints.only('md')]: {
            margin: "0px 200px",
        },
        [theme.breakpoints.only('sm')]: {
            margin: "0px 150px",
        },
        [theme.breakpoints.only('xs')]: {
            margin: "0px",
        },
    },
    title: {
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: '20px',
        color: '#484848',
        paddingBottom: '20px',
        borderBottom: "1px dashed #EDEDED"
    },
    divTicket: {
        padding: '15px 15px 15px',
        color: '#484848',
        fontWeight: 'bold',
        fontSize: '22px',
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center'
    },
    textTicket: {
        color: '#7D7D7D',
        padding: '0px 15px',
        fontSize: '14px',
        textAlign: 'center',
        paddingBottom: '25px'
    },
    btnCopy: {
        width: '10%',
        padding: '0px',
        marginLeft: '10px',
        cursor: 'pointer',
        [theme.breakpoints.only('xs')]: {
            width: '12%',
        },
        [theme.breakpoints.only('md')]: {
            width: '8%',
        },
    },
    btnCopyTo: {
        width: '10%',
        padding: '0px',
        margin: '10px 0px 0px',
        cursor: 'pointer',
        display: 'flex',
        [theme.breakpoints.only('xs')]: {
            width: '12%',
        },
        [theme.breakpoints.up('lg')]: {
            margin: '0px 0px 0px 10px',
        },
    },
    divProcess: {
        marginTop: '-50px',
        marginBottom: '20px',
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            marginTop: '-40px',
        },
    },
    processIcon: {
        width: '16%',
        padding: '0px',
        [theme.breakpoints.only('md')]: {
            width: '16%',
        },
    },
    divTopic: {
        padding: '15px 5px',
        color: '#7D7D7D',
        fontSize: '16px'
    },
    divText: {
        padding: '5px 5px 15px 5px',
        fontWeight: 'bold',
        color: '#484848',
        fontSize: '14px',
        alignItems: 'center',
        display: 'flex',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    divTextTo: {
        padding: '5px 5px 15px 5px',
        fontWeight: 'bold',
        color: '#484848',
        fontSize: '14px',
        alignItems: 'center',
        display: 'flow-root',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        [theme.breakpoints.up('lg')]: {
            display: 'flex',
        },
    },
    divTopicTime: {
        padding: '15px 5px',
        color: '#7D7D7D',
        fontSize: '16px',
        textAlign: 'end'
    },
    divTextTime: {
        padding: '5px 5px 15px 5px',
        fontWeight: 'bold',
        color: '#484848',
        fontSize: '16px',
        textAlign: 'end'
    },
    divBtnHome: {
        textAlign: 'center',
        padding: '50px 0px'
    },
    btnHome: {
        backgroundColor: "#FFD76A",
        color: '#484848',
        width: '25%',
        fontSize: '20px',
        fontWeight: 'bold',
        borderRadius: '35px',
        textTransform: 'capitalize',
        '&:hover': {
            backgroundColor: "#FFD76A",
        },
        [theme.breakpoints.only('xs')]: {
            width: '80%',
        },
        [theme.breakpoints.only('sm')]: {
            width: '35%',
        },
        [theme.breakpoints.only('md')]: {
            width: '40%',
        },
    },
}))

export default showProcessStyle