import React, { memo, useEffect } from "react";
// import React, { memo } from "react"
import { FormattedMessage } from "react-intl";
import messages from "./messages";

// Firebase App (the core Firebase SDK) is always required and must be listed first
import * as firebase from "firebase/app";
// If you enabled Analytics in your project, add the Firebase SDK for Analytics
import "firebase/analytics";

import { compose } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Header from "../../components/Header";

import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";

import CopyIcon from "../../assets/icons/copy.png";
import InProcess from "../../assets/icons/inprocess.png";
import showProcessStyle from "./showProcessStyle";
import { Button, Grid } from "@material-ui/core";
import { ticketService } from "../../services";
import _ from "lodash";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Confirmation from "../Confirmation";

function ShowProcess(props) {
  const classes = showProcessStyle();
  //_.get(props.confirmation, 'ticketid')
  let ticket = _.get(props.confirmation, "ticketid");
  let checkstatus = _.get(props.confirmation, "checkstatus");
  let status = _.get(props.confirmation, "status");
  const [tiketid, setTiketid] = React.useState("");
  const [firstname, setFirstname] = React.useState("");
  const [lastname, setLastname] = React.useState("");
  const [six_stellar_addr, setSix_stellar_addr] = React.useState("");
  const [memo_text, setMemo_text] = React.useState("");
  const [confirm_date, setconfirm_date] = React.useState("");
  const [confirm_time, setconfirm_time] = React.useState("");

  useEffect(() => {
    firebase.analytics().setCurrentScreen("ShowProcessScreen");
    props.history.push("/");
    console.log("Ticket ID = ", ticket);
    if (checkstatus === "checkstatus") {
      try {
        ticketService
          .getTicket(status)
          .then((resp) => {
            if (resp.success === true) {
              let data = _.get(resp, "data");
              setTiketid(_.get(data, "ticket_id"));
              setFirstname(_.get(data, "firstname"));
              setLastname(_.get(data, "lastname"));
              setSix_stellar_addr(_.get(data, "six_stellar_addr"));
              setMemo_text(_.get(data, "memo_text"));
              let getDate = _.get(data, "confirm_date");
              let changeDate = new Date(getDate * 1000);
              let formatDate =
                changeDate.getDate() +
                " " +
                changeDate.toLocaleString("en-us", { month: "long" }) +
                " " +
                changeDate.getFullYear();

              let formatTime = changeDate.toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
              });
              setconfirm_time(formatTime);
              setconfirm_date(formatDate);
              console.log("resp", resp);
            } else {
              console.log("resp", resp.error);
            }
          })
          .catch((error) => {
            console.log("resp", error);
          });
      } catch (error) {}
    } else {
      try {
        ticketService
          .getTicket(ticket)
          .then((resp) => {
            if (resp.success === true) {
              let data = _.get(resp, "data");
              setTiketid(_.get(data, "ticket_id"));
              setFirstname(_.get(data, "firstname"));
              setLastname(_.get(data, "lastname"));
              setSix_stellar_addr(_.get(data, "six_stellar_addr"));
              setMemo_text(_.get(data, "memo_text"));
              let getDate = _.get(data, "confirm_date");
              let changeDate = new Date(getDate * 1000);
              let formatDate =
                changeDate.getDate() +
                " " +
                changeDate.toLocaleString("en-us", { month: "long" }) +
                " " +
                changeDate.getFullYear();

              let formatTime = changeDate.toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
              });
              setconfirm_time(formatTime);
              setconfirm_date(formatDate);
              console.log("resp", resp);
            } else {
              console.log("resp", resp.error);
            }
          })
          .catch((error) => {
            console.log("resp", error);
          });
      } catch (error) {}
    }
  }, [ticket, checkstatus, props, status]);

  const goToHomeScreen = () => {
    firebase.analytics().logEvent("click_go_to_home_screen");

    props.history.push("/");
  };

  return (
    <div className={classes.root}>
      <Header />
      <Container maxWidth="xl" className={classes.container}>
        <Paper className={classes.paper}>
          <div className={classes.divProcess}>
            <img
              src={InProcess}
              alt="InProcess"
              className={classes.processIcon}
            ></img>
          </div>
          <div className={classes.title}>
            <FormattedMessage {...messages.title} />
          </div>
          <div className={classes.divTicket}>
            <FormattedMessage {...messages.ticketID} />
            &nbsp;{tiketid}
            <CopyToClipboard text={tiketid}>
              <img
                src={CopyIcon}
                alt="CopyIcon"
                className={classes.btnCopy}
              ></img>
            </CopyToClipboard>
          </div>
          <div className={classes.textTicket}>
            <FormattedMessage {...messages.warning} />
          </div>

          <div className={classes.divTopic}>
            <FormattedMessage {...messages.name} />
          </div>
          <div className={classes.divText}>
            <span>
              {firstname} {lastname}
            </span>
          </div>
          <div className={classes.divTopic}>
            <FormattedMessage {...messages.to} />
          </div>
          <div className={classes.divTextTo}>
            <span>{six_stellar_addr}</span>
            <CopyToClipboard text={six_stellar_addr}>
              <img
                src={CopyIcon}
                alt="CopyIcon"
                className={classes.btnCopyTo}
              ></img>
            </CopyToClipboard>
          </div>
          <div className={classes.divTopic}>
            <FormattedMessage {...messages.memotype} />
          </div>
          <div className={classes.divText}>
            <span>{memo_text}</span>
            <CopyToClipboard text={memo_text}>
              <img
                src={CopyIcon}
                alt="CopyIcon"
                className={classes.btnCopy}
              ></img>
            </CopyToClipboard>
          </div>
          <Grid container>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <div className={classes.divTopic}>
                <FormattedMessage {...messages.date} />
              </div>
              <div className={classes.divText}>
                <span>{confirm_date}</span>
              </div>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <div className={classes.divTopicTime}>
                <FormattedMessage {...messages.time} />
              </div>
              <div className={classes.divTextTime}>
                <span>{confirm_time}</span>
              </div>
            </Grid>
          </Grid>
        </Paper>
        <div className={classes.divBtnHome}>
          <Button onClick={goToHomeScreen} className={classes.btnHome}>
            <FormattedMessage {...messages.btnHome} />
          </Button>
        </div>
      </Container>
    </div>
  );
}

Confirmation.prototypes = {
  locale: PropTypes.string,
  data: PropTypes.string,
  onData: PropTypes.func,
  onVerify: PropTypes.func,
};

const mapStateToProps = (state, ownProps) => {
  return {
    locale: state.language.locale,
    information: state.information.information,
    verify: state.verify.verify,
    confirmation: state.confirmation.confirmation,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    // informationSelected: information => {
    //   dispatch(informationActions.informationSelected(information))
    // }
    //onData: (key) => dispatch(informationSelected(key)),
    //onVerify: (key) => dispatch(verifySelected(key))
  };
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect, memo)(ShowProcess);
