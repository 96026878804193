import { makeStyles } from '@material-ui/core/styles';
const informationStyle = makeStyles(theme => ({
    root: {
        backgroundImage: "linear-gradient(to right,#8E2DE2,#4A00E0)",
        flexGrow: 1,
        [theme.breakpoints.between('xs', 'lg')]: {
            height: '100vh',
        }
    },
    container: {
        padding: "100px 60px 60px 60px",
        [theme.breakpoints.only('xs')]: {
            padding: "80px 20px 40px 20px",
        },
        [theme.breakpoints.only('sm')]: {
            padding: "100px 40px 40px 40px",
        },
        [theme.breakpoints.only('md')]: {
            padding: "140px 40px 40px 40px",
        },
    },
    title: {
        color: '#fff',
        fontSize: '24px',
        textAlign: 'center',
        padding: '50px 100px',
        [theme.breakpoints.only('xs')]: {
            padding: '50px 0px',
        },
    },
    paper: {
        margin: "0px 400px",
        borderTop: "6px solid #EBBE44",
        borderRadius: '2px',
        padding: '0px 15px 15px',
        [theme.breakpoints.only('md')]: {
            margin: "0px 200px",
        },
        [theme.breakpoints.only('sm')]: {
            margin: "0px 150px",
        },
        [theme.breakpoints.only('xs')]: {
            margin: "0px",
        },
    },
    divTopic: {
        padding: '15px',
        color: '#7D7D7D',
    },
    divText: {
        padding: '15px',
        alignItems: 'center',
        display: 'flex',
        '& .MuiInput-underline:before': {
            content: 'none'
        },
        '& .MuiInput-underline:after': {
            borderBottom: 'unset'
        },

    },
    divMobileNumber: {
        padding: '0px 15px 15px',
        alignItems: 'center',
        display: 'flex',
        '& .MuiInput-underline:before': {
            content: 'none'
        },
        '& .MuiInput-underline:after': {
            borderBottom: 'unset'
        },
        '& .react-tel-input .form-control': {
            width: '100px',
            height: '35px',
            fontSize: '15px',
            borderTop: 'unset',
            borderBottom: 'unset',
            borderLeft: 'unset',
            borderRadius: '0px'
        },
        '& .react-tel-input': {
            width: '95px'
        },
        '& .react-tel-input .flag-dropdown': {
            borderRadius: '3px'
        }
    },
    divBtnNext: {
        textAlign: 'center',
        padding: '50px 0px'
    },
    btnNext: {
        backgroundColor: "#FFD76A",
        color: '#484848',
        width: '25%',
        fontSize: '20px',
        fontWeight: 'bold',
        borderRadius: '35px',
        textTransform: 'capitalize',
        '&:hover': {
            backgroundColor: "#FFD76A",
        },
        [theme.breakpoints.only('xs')]: {
            width: '80%',
        },
        [theme.breakpoints.only('sm')]: {
            width: '35%',
        },
        [theme.breakpoints.only('md')]: {
            width: '40%',
        },
    },
    divMenu: {
        '& .MuiList-padding': {
            paddingTop: '0px',
            paddingBottom: '0px'
        },
        '& .MuiSelect-select.MuiSelect-select': {
            padding: '10px 16px 10px 0px'
        }
    },
    flagIcons: {
        width: '30px',
        height: '18px',
        paddingRight: '6px',
        [theme.breakpoints.only('xs')]: {
            paddingRight: '14px',
        },
    },
    flagIconsKo: {
        width: '33px',
        height: '20px',
        paddingRight: '6px',
    },
    laguage: {
        paddingBottom: '2px',
        '& .MuiSelect-select.MuiSelect-select': {
            paddingBottom: '4px',
        },
        '& .MuiList-padding': {
            padding: '0px'
        }
    },
    textName: {
        width: '80%',
        backgroundColor: 'unset',
        '& .MuiInputBase-input': {
            padding: '20px 0px 0px',
        },
        '& .input:-internal-autofill-selected': {
            backgroundColor: 'unset'
        },
        '& .MuiFormLabel-root.Mui-focued': {
            color: '#7D7D7D'
        },
        '&.Mui-focused fieldset': {
            borderColor: 'green',
        },

    },
    textStyle: {
        width: '80%',
        backgroundColor: 'unset',
        '& .MuiInputBase-input': {
            padding: '0px 10px',
            fontSize: '15px',
        },
    },
}))
export default informationStyle