import React, { memo, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { FormattedMessage } from "react-intl";
import messages from "./messages";
import Container from "@material-ui/core/Container";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
// import TextField from '@material-ui/core/TextField';
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
//import Radio from '@material-ui/core/Radio';
import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles } from "@material-ui/core/styles";

import Header from "../../components/Header";
import conditionsStyle from "./conditionsStyle";
import Success from "../../assets/icons/success.png";

import { txacceptService } from "../../services";
import _ from "lodash";
import clsx from "clsx";
import axios from "axios";
import { conditionsSelected } from "../../actions";

// Firebase App (the core Firebase SDK) is always required and must be listed first
import * as firebase from "firebase/app";
// If you enabled Analytics in your project, add the Firebase SDK for Analytics
import "firebase/analytics";

//const useStyles = makeStyles({
//    root: {
//        '&:hover': {
//            backgroundColor: 'transparent',
//        },
//    },
//    icon: {
//        borderRadius: '50%',
//        width: 16,
//        height: 16,
//        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
//        backgroundColor: '#f5f8fa',
//        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
//        '$root.Mui-focusVisible &': {
//            outline: '2px auto rgba(19,124,189,.6)',
//            outlineOffset: 2,
//        },
//        'input:hover ~ &': {
//            backgroundColor: '#ebf1f5',
//        },
//        'input:disabled ~ &': {
//            boxShadow: 'none',
//            background: 'rgba(206,217,224,.5)',
//        },
//    },
//    checkedIcon: {
//        backgroundColor: '#137cbd',
//        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
//        '&:before': {
//            display: 'block',
//            width: 16,
//            height: 16,
//            backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
//            content: '""',
//        },
//        'input:hover ~ &': {
//            backgroundColor: '#7D7D7D',
//        },
//    },
//});

const checkox = makeStyles({
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  icon: {
    borderRadius: 10,
    width: 16,
    height: 16,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
  },
  checkedIcon: {
    backgroundColor: "#137cbd",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 16,
      height: 16,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "#106ba3",
    },
  },
});

function StyledCheckbox(props) {
  const classes = checkox();

  return (
    <Checkbox
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      inputProps={{ "aria-label": "decorative checkbox" }}
      {...props}
    />
  );
}

// function StyledRadio(props) {
//    const classes = useStyles();
//    return (
//         <Radio
//             className={classes.root}
//             disableRipple
//             color="default"
//            checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
//          icon={<span className={classes.icon} />}
//             {...props}
//         />
//     );
// }

function Conditions(props) {
  const classes = conditionsStyle();
  const { locale } = props;
  //const [code, setCode] = React.useState('');
  // const [value, setValue] = React.useState('');
  //sconst [ setValue] = React.useState('female');
  const [checkbox1, setCheckbox1] = React.useState(false);
  const [checkbox2, setCheckbox2] = React.useState(false);
  const [term_of_uses, setTerm_of_uses] = React.useState([]);
  useEffect(() => {
    firebase.analytics().setCurrentScreen("TermsAndConditionsScreen");
    props.history.push("/");
  });

  useEffect(() => {
    axios
      .get(
        "https://database-s3public-1xiy843bek232.s3-ap-southeast-1.amazonaws.com/official_doc/six_swap_terms_and_conditions.json?fbclid=IwAR2mnVppzxVyF-W30Uc4z7MfzM04-NOcTR1NG6-qHcKbWI-zX4bCFd7ZGDw"
      )
      .then((response) => {
        setTerm_of_uses(response.data.term_of_uses);
      });
  }, [term_of_uses]);

  let kct = _.get(props.swap, "klaytn");

  const changePage = () => {
    let txid = _.get(props.swap, "txid");
    let mobile = _.get(props.swap, "mobile");
    let kct = _.get(props.swap, "klaytn");
    try {
      txacceptService
        .updateTx(txid, mobile)
        .then((resp) => {
          firebase.analytics().logEvent("click_accept_terms");

          if (resp.success === true) {
            if (checkbox1 === true && checkbox2 === true) {
              let data = _.get(resp, "data");
              let steller = _.get(data, "six_stellar_addr");
              let memo = _.get(data, "memo_text");

              let datas = {
                txid,
                mobile,
                kct,
                memo,
                steller,
              };

              props.onConditions(datas);

              props.history.push("/tranfer");
            }
            console.log("resp", resp);
          } else {
            console.log("resp", resp.error);
          }
        })
        .catch((error) => {
          console.log("resp", error);
        });
    } catch (error) {}
  };

  const handlecheckbox1 = (event) => {
    setCheckbox1(event.target.checked);
  };
  const handlecheckbox2 = (event) => {
    setCheckbox2(event.target.checked);
  };

  return (
    <div className={classes.root}>
      <Header history={props.history} />
      <Container maxWidth="xl" className={classes.container}>
        <div className={classes.title}>
          <FormattedMessage {...messages.title1} />
        </div>
        <div className={classes.title2}>
          <FormattedMessage {...messages.title2} />
        </div>
        <FormControl fullWidth className={classes.margin} variant="outlined">
          <OutlinedInput
            value={kct}
            id="kct"
            disabled
            endAdornment={
              <InputAdornment position="end">
                <img
                  src={Success}
                  alt="Success"
                  className={classes.successIcon}
                ></img>
              </InputAdornment>
            }
          />
        </FormControl>
        <div className={classes.title3}>
          <FormattedMessage {...messages.termofcondition} />
        </div>
        <div className={classes.borderBox}>
          {locale === "en"
            ? term_of_uses.map((t) => {
                return (
                  <div>
                    <span className={classes.termsAndCondTitle}>
                      {t.title === "" ? "" : t.title}
                    </span>
                    <span className={classes.termsAndCondText}>
                      {t.body === "" ? "" : t.body}
                    </span>
                  </div>
                );
              })
            : term_of_uses.map((t) => {
                return (
                  <div>
                    <span className={classes.termsAndCondTitle}>
                      {t.title_ko === "" ? "" : t.title_ko}
                    </span>
                    <span className={classes.termsAndCondText}>
                      {t.body_ko === "" ? "" : t.body_ko}
                    </span>
                  </div>
                );
              })}
        </div>
        <FormControl component="fieldset">
          {/* <FormLabel component="legend">Gender</FormLabel> */}
          <div className={classes.radioStyle}>
            <StyledCheckbox
              onChange={(event, index) => handlecheckbox1(event)}
            />
            <span className={classes.divAccept}>
              <FormattedMessage {...messages.accept} />
            </span>
            &nbsp;
            <span className={classes.divTerm}>
              <FormattedMessage {...messages.termofcondition} />
            </span>
          </div>
          <div className={classes.radioStyle}>
            <StyledCheckbox
              onChange={(event, index) => handlecheckbox2(event)}
            />
            <span className={classes.divAccept}>
              <FormattedMessage {...messages.surethat} />
            </span>
            &nbsp;
            <span className={classes.divTerm}>
              <FormattedMessage {...messages.klaytnwallet} />
            </span>
          </div>
          {/* <RadioGroup defaultValue="female" aria-label="gender" name="customized-radios">
                        <div className={classes.radioStyle}>

                            <FI accept this ormControlLabel value="female" control={<StyledRadio />} />
                            <span className={classes.divAccept}>I accept this </span>
                            <span className={classes.divTerm}>Terms and Conditions.</span>
                        </div>
                        <div className={classes.radioStyle}>
                            <FormControlLabel value="male" control={<StyledRadio />} />
                            <span className={classes.divAccept}>I’m sure that I have this </span>
                            <span className={classes.divTerm}>Klaytn wallet’s private key.</span>
                        </div>
                    </RadioGroup> */}
        </FormControl>
        {checkbox1 && checkbox2 ? (
          <div className={classes.divBtnNext}>
            <Button onClick={changePage} className={classes.btnNext}>
              <FormattedMessage {...messages.btnNext} />
            </Button>
          </div>
        ) : (
          <div className={classes.divBtnNext}>
            <Button disabled={true} className={classes.btnNext}>
              <FormattedMessage {...messages.btnNext} />
            </Button>
          </div>
        )}

        {/* <FormControl component="fieldset">
                    <RadioGroup aria-label="gender" name="gender1" value={value} onChange={handleChange}>
                        <div className={classes.radioStyle}>
                            <FormControlLabel value="female" control={<Radio />} />
                            <span className={classes.divAccept}>I accept this </span>
                            <span className={classes.divTerm}>Terms and Conditions.</span>
                        </div>
                        <div className={classes.radioStyle}>
                            <FormControlLabel value="male" control={<Radio />} />
                            <span className={classes.divAccept}>I’m sure that I have this </span>
                            <span className={classes.divTerm}>Klaytn wallet’s private key.</span>
                        </div>
                    </RadioGroup>
                </FormControl> */}
      </Container>
    </div>
  );
}
Conditions.propTypes = {
  locale: PropTypes.string,
  data: PropTypes.string,
  onSwap: PropTypes.func,
  onConditions: PropTypes.func,
};

const mapStateToProps = (state, ownProps) => {
  return {
    locale: state.language.locale,
    swap: state.swap.swap,
    conditions: state.conditions.conditions,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onConditions: (key) => dispatch(conditionsSelected(key)),
    //   onLocaleToggle: (key) => dispatch(changeLang(key)),
  };
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(Conditions);
