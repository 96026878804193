import { tranferConstants } from '../constants'


export const tranferSelected = (tranfer) => {
    return async (dispatch) => {
        dispatch({ type : tranferConstants.TRANFER_SELECTED, tranfer})
    }
}

// export const informationActions = {
//      informationSelected
// }