import { verifyConstants } from '../constants'


export const verifySelected = (verify) => {
    return async (dispatch) => {
        dispatch({ type : verifyConstants.VERIFY_SELECTED, verify})
    }
}

// export const informationActions = {
//      informationSelected
// }