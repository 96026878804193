import React, { memo, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import messages from "./messages";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { changeLang } from "../../actions";

// Firebase App (the core Firebase SDK) is always required and must be listed first
import * as firebase from "firebase/app";
// If you enabled Analytics in your project, add the Firebase SDK for Analytics
import "firebase/analytics";

import { compose } from "redux";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Divider from "@material-ui/core/Divider";
import MenuItem from "@material-ui/core/MenuItem";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";

// import CloseIcon from "@material-ui/icons/Close";

import homepageStyle from "./homepageStyle";

import Logo from "../../assets/img/logo.png";
import Reasons from "../../assets/img/reason.png";
import PoweredBySIX from "../../assets/img/poweredBySIX.png";
// import ImporNote from "../../assets/img/important-note.png";
//import Thai from "../../assets/icons/thai.png";
import Eng from "../../assets/icons/eng.png";
import Korea from "../../assets/icons/korea.png";

function HomePage(props) {
  const { locale } = props;
  const classes = homepageStyle();

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    handleOpenModal();
    firebase.analytics().setCurrentScreen("HomeScreen");
  });

  const handleChange = (event) => {
    props.onLocaleToggle(event.target.value);
  };

  // const [open, setOpen] = React.useState(false);
  // const handleOpen = () => {
  //   setOpen(true);
  // };

  // const handleClose = () => {
  //   setOpen(false);
  // };

  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  // const goToSwap = () => {
  //   firebase.analytics().logEvent("click_goto_swap_btn");
  //   props.history.push("/info");
  // };

  // const goToCheckTicket = () => {
  //   firebase.analytics().logEvent("click_check_swap_btn");
  //   props.history.push("/check-swap");
  // };

  return (
    <div className={classes.root}>
      <Container maxWidth="xl" className={classes.container}>
        <div style={{ textAlign: "end" }}>
          <TextField
            id="select-locale"
            select
            value={locale}
            onChange={handleChange}
            className={classes.divMenu}
          >
            <MenuItem value="en">
              <img src={Eng} alt="Eng" className={classes.flagIcons}></img>
              <FormattedMessage {...messages.eng} />
            </MenuItem>
            <Divider />
            <MenuItem value="ko">
              <img src={Korea} alt="Korea" className={classes.flagIcons}></img>
              <FormattedMessage {...messages.korea} />
            </MenuItem>
          </TextField>
        </div>
        <Grid container className={classes.gridCon}>
          <Grid
            item
            lg={6}
            md={6}
            sm={12}
            xs={12}
            className={classes.gridRight}
          >
            <Paper className={classes.paper}>
              <div className={classes.title}>
                <FormattedMessage {...messages.title} />
              </div>
              <div className={classes.divImage}>
                <img
                  src={Reasons}
                  alt="Reasons"
                  className={classes.image}
                ></img>
              </div>
              <div>
                <ol>
                  <li>
                    <FormattedMessage {...messages.detail1} />
                  </li>
                  <li className={classes.list}>
                    <FormattedMessage {...messages.detail2} />
                  </li>
                  <li className={classes.list}>
                    <FormattedMessage {...messages.detail3} />
                  </li>
                </ol>
              </div>
            </Paper>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <div className={classes.divLogo}>
              <img src={Logo} alt="Logo" className={classes.logo}></img>
            </div>
            <div className={classes.divImage}>
              <Button className={classes.btnSwap}>
                <FormattedMessage {...messages.swap} />
              </Button>
              {/* <Modal
                aria-labelledby="title"
                aria-describedby="description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
              >
                <Fade in={open}>
                  <div className={classes.paperModal}>
                    <div className={classes.closeIcon}>
                      <CloseIcon onClick={handleClose} />
                    </div>
                    <div className={classes.divImageNote}>
                      <img
                        src={ImporNote}
                        alt="ImporNote"
                        className={classes.imporNote}
                      ></img>
                    </div>
                    <span className={classes.title1}>
                      <FormattedMessage {...messages.titleModal1} />
                    </span>
                    <span className={classes.title2}>
                      <FormattedMessage {...messages.titleModal2} />
                    </span>
                    <ol>
                      <li className={classes.list}>
                        <FormattedMessage {...messages.detailModal1} />
                      </li>
                      <li className={classes.list}>
                        <FormattedMessage {...messages.detailModal2} />
                      </li>
                      <li className={classes.list}>
                        <FormattedMessage {...messages.detailModal3} />
                      </li>
                    </ol>
                    <div className={classes.divBtnModal}>
                      <Button className={classes.btnDone}>
                        <FormattedMessage {...messages.btnDone} />
                      </Button>
                    </div>
                  </div>
                </Fade>
              </Modal> */}
            </div>
            <div className={classes.divBtn}>
              <Button className={classes.btnCheck}>
                <FormattedMessage {...messages.checkswap} />
              </Button>
            </div>
            <div className={classes.divSix}>
              <img
                src={PoweredBySIX}
                alt="PoweredBySIX"
                className={classes.sixImage}
              ></img>
            </div>
          </Grid>
        </Grid>
      </Container>
      <Modal
        className={classes.modal}
        open={openModal}
        onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModal}>
          <div className={classes.paperModalTobridge}>
            <p>We are sorry for any inconvenience</p>
            <span className={classes.titleModal}>SIX swap has changed to</span>
            <h1>
              <a href="https://bridge.six.network/">SIX Bridge</a>
            </h1>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

HomePage.propTypes = {
  onLocaleToggle: PropTypes.func,
  locale: PropTypes.string,
};

const mapStateToProps = (state, ownProps) => {
  return {
    locale: state.language.locale,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onLocaleToggle: (key) => dispatch(changeLang(key)),
  };
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(HomePage);
