/*
 * Confirmation Messages
 *
 * This contains all the text for the Confirmation container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.Confirmation';

export default defineMessages({
  title1: {
    id: `${scope}.title1`,
    defaultMessage: 'Swap confirmation',
  },
  title2: {
    id: `${scope}.title2`,
    defaultMessage: 'Please transfer SIX on Stellar to:'
  },
  memotype: {
    id: `${scope}.memotype`,
    defaultMessage: 'Memo type : Text'
  },
  remark: {
    id: `${scope}.remark`,
    defaultMessage: 'Remark : Upon completion of the swap, the converted SIX-KCT token will be sent to your Klaytn wallet address within 15 minutes.'
  },
  btnConfirm: {
    id: `${scope}.btnConfirm`,
    defaultMessage: 'Confirm'
  },
});
