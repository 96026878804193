import { API } from 'aws-amplify'
// import moment from 'moment'

const updateTx = (
    tx_id,
    phone_number,
) => {
    return API.put('*', '/tx/accept_terms', {
        // headers: {
        //     'x-six-authorization': token,
        // },
        body: {
            tx_id: tx_id,
            phone_number: phone_number,
        },
    })
}


export const txacceptService = {
    updateTx,
}
