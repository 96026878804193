import { makeStyles } from '@material-ui/core/styles';
const confirmationStyle = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: '#F5F5F8',
        height: '100vh',
        [theme.breakpoints.between('xs', 'md')]: {
            height: '100vh',
        },
    },
    container: {
        padding: "100px 60px 60px 60px",
        [theme.breakpoints.only('xs')]: {
            padding: "100px 20px 40px 20px",
        },
        [theme.breakpoints.only('sm')]: {
            padding: "100px 40px 40px 40px",
        },
        [theme.breakpoints.only('md')]: {
            padding: "100px 60px 60px 60px",
        },
    },
    title: {
        color: '#fff',
        fontSize: '24px',
        textAlign: 'center',
        padding: '50px 100px',
        [theme.breakpoints.only('xs')]: {
            padding: '50px 0px',
        },
    },
    paper: {
        paddingTop: '40px',
        textAlign: "center",
        margin: "0px 400px",
        // borderTop: "6px solid #EBBE44",
        borderRadius: '2px',
        padding: '15px',
        [theme.breakpoints.only('md')]: {
            margin: "0px 200px",
        },
        [theme.breakpoints.only('sm')]: {
            margin: "0px 150px",
        },
        [theme.breakpoints.only('xs')]: {
            margin: "0px",
        },

    },
    divTopic: {
        padding: '15px',
        color: '#7D7D7D',
    },
    divText: {
        border: '1px solid red',
        padding: '5px 15px 15px',
        '& .MuiInput-underline:before': {
            content: 'none'
        },
        '& .MuiInput-underline:after': {
            borderBottom: 'unset'
        }
    },
    divBtnNext: {
        textAlign: 'center',
        padding: '50px 0px'
    },
    btnNext: {
        backgroundColor: "#4A00E0",
        color: '#ffffff',
        width: '25%',
        fontSize: '20px',
        fontWeight: 'bold',
        borderRadius: '35px',
        textTransform: 'capitalize',
        '&:hover': {
            backgroundColor: "#4A00E0",
        },
        [theme.breakpoints.only('xs')]: {
            width: '80%',
        },
        [theme.breakpoints.only('sm')]: {
            width: '35%',
        },
        [theme.breakpoints.only('md')]: {
            width: '40%',
        },
    },
    divVerify: {
        padding: '5px 15px 15px',
        color: '#484848',
        fontSize: '22px',
        fontWeight: 'bold',
        textAlign: 'center'
    },
    divStyle: {
        alignContent: 'center',
        marginTop: '20px'
    },
    divTryagain: {
        textAlign: 'center',
        paddingTop: '20px'
    },
    divSms: {
        fontSize: '20px',
        color: '#ffffff'
    },
    divTry: {
        fontSize: '20px',
        color: '#FFD76A'
    },
    textField: {
        // width:'100%',
        margin: '18px 0px',
        '& .MuiOutlinedInput-inputMarginDense': {
            padding: '15px 10px'
        },
        '&:hover': {
            border: 'unset'
        },
        '& .MuiOutlinedInput:focus': {
            outline: 0
        },
    },
    successIcon: {
        width: '25%',
        paddingTop: '35px',
        paddingBottom: '35px',
        [theme.breakpoints.only('sm')]: {
            paddingTop: '30px',
            paddingBottom: '30px',
        },
        [theme.breakpoints.only('xs')]: {
            paddingTop: '30px',
            paddingBottom: '30px',
        },
    },
    divIcons: {
        textAlign: 'center'
    },
    inputProps: {
        step: 300,
    },
    pasteSection: {
        // height: '400px',
        overflow: 'auto',
        marginTop: '18px'
    },
    pasteTextField: {
        width: '100%',
        minHeight: '325px',
        backgroundColor: '#BABABA15',
        borderColor: '#7D7D7D15'
    },
    divAccept: {
        fontSize: '18px',
        color: '#7D7D7D'
    },
    divTerm: {
        fontSize: '18px',
        color: '#7D7D7D',
        fontWeight: 'bold'
    },
    radioStyle: {
        marginTop: '20px',
        color: '#7D7D7D',
        '& .makeStyles-checkedIcon-39': {
            backgroundColor: '#7D7D7D'
        }
    },
    stellarktcIcon: {
        width: '25%',
        paddingTop: '35px',
        paddingBottom: '35px',
        [theme.breakpoints.only('sm')]: {
            paddingTop: '30px',
            paddingBottom: '30px',
        },
        [theme.breakpoints.only('xs')]: {
            paddingTop: '30px',
            paddingBottom: '30px',
        },
    },
    divstellarktcIcon: {
        textAlign: 'center',
        '& .makeStyles-stellarktcIcon-23': {
            width: '35%'
        }
    },
    divtextconfirmation: {
        fontSize: '30px',
        color: '#7D7D7D'
    },
    divsixonstellar: {
        fontSize: '20px',
        color: '#7D7D7D',
        marginTop: '30px'
    },
    divtextmemo: {
        fontSize: '20px',
        color: '#7D7D7D'
    },
    divtextremarks: {
        fontSize: '20px',
        color: '#7D7D7D',
        marginTop: '20px'
    },
    marginInput: {
        margin: '20px 0px',
        border: 'unset',
        '& .Mui-focused': {
            border: 'unset'
        },
        '& .MuiOutlinedInput-adornedEnd': {
            paddingRight: "0px"
        },
        [theme.breakpoints.down('md')]: {
            margin: '0px',
        },
        [theme.breakpoints.up('lg')]: {
            margin: '10px 0px',
        },
    },
}))

export default confirmationStyle