import { swapConstants } from '../constants'


export const swapSelected = (swap) => {
    return async (dispatch) => {
        dispatch({ type : swapConstants.SWAP_SELECTED, swap})
    }
}

// export const informationActions = {
//      informationSelected
// }