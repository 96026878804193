import { API } from 'aws-amplify'
// import moment from 'moment'

const updateVerify = (
    tx_id,
    action,
    phone_number,
    confirm_code,
    ref_no,
) => {
    return API.put('*', '/otp/verify', {
        // headers: {
        //     'x-six-authorization': token,
        // },
        body: {
            tx_id: tx_id,
            action: action,
            phone_number: phone_number,
            confirm_code: confirm_code,
            ref_no: ref_no,
        },
    })
}

export const verifyService = {
    updateVerify,
}
