/*
 * Header Messages
 *
 * This contains all the text for the Header container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.Header';

export default defineMessages({
  header1: {
    id: `${scope}.header1`,
    defaultMessage: 'Swap',
  },
  header2:{
    id: `${scope}.header2`,
    defaultMessage: 'Check Swap Status'
  },
  thai: {
    id: `${scope}.thai`,
    defaultMessage: 'ไทย',
  },
  eng: {
    id: `${scope}.eng`,
    defaultMessage: 'English',
  },
  korea: {
    id: `${scope}.korea`,
    defaultMessage: '한글',
  },
 
});
