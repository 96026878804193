import { makeStyles } from '@material-ui/core/styles';
const tranferStyle = makeStyles(theme => ({
    root: {
        // backgroundImage: "linear-gradient(to right, #8E2DE2,#4A00E0)",
        flexGrow: 1,
        backgroundColor: '#F5F5F8',
        // height: '100vh',
        [theme.breakpoints.only('md')]: {
            height: '100vh',
        },
    },
    container: {
        padding: "100px 60px 60px 60px",
        [theme.breakpoints.only('xs')]: {
            padding: "100px 20px 40px 20px",
        },
        [theme.breakpoints.only('sm')]: {
            padding: "110px 40px 40px 40px",
        },
    },
    title: {
        color: '#fff',
        fontSize: '24px',
        textAlign: 'center',
        padding: '50px 100px',
        [theme.breakpoints.only('xs')]: {
            padding: '50px 0px',
        },
    },
    paper: {
        paddingTop: '40px',
        textAlign: "center",
        margin: "0px 400px",
        borderRadius: '2px',
        padding: '15px',
        [theme.breakpoints.only('md')]: {
            margin: "0px 200px",
        },
        [theme.breakpoints.only('sm')]: {
            margin: "0px 150px",
        },
        [theme.breakpoints.only('xs')]: {
            margin: "0px",
        },
    },
    divTopic: {
        padding: '15px',
        color: '#7D7D7D',
    },
    divText: {
        border: '1px solid red',
        padding: '5px 15px 15px',
        '& .MuiInput-underline:before': {
            content: 'none'
        },
        '& .MuiInput-underline:after': {
            borderBottom: 'unset'
        }
    },
    divBtnNext: {
        textAlign: 'center',
        padding: '50px 0px 70px',
        [theme.breakpoints.only('xs')]: {
            padding: '30px 0px',
        },
    },
    btnNext: {
        backgroundColor: "#4A00E0",
        color: '#ffffff',
        width: '25%',
        fontSize: '20px',
        fontWeight: 'bold',
        borderRadius: '35px',
        textTransform: 'capitalize',
        '&:hover': {
            backgroundColor: "#4A00E0",
        },
        [theme.breakpoints.only('xs')]: {
            width: '80%',
        },
        [theme.breakpoints.only('sm')]: {
            width: '35%',
        },
        [theme.breakpoints.only('md')]: {
            width: '40%',
        },
    },
    divVerify: {
        padding: '5px 15px 15px',
        color: '#484848',
        fontSize: '22px',
        fontWeight: 'bold',
        textAlign: 'center'
    },
    divStyle: {
        alignContent: 'center',
        marginTop: '20px'
    },
    divTryagain: {
        textAlign: 'center',
        paddingTop: '20px'
    },
    divSms: {
        fontSize: '20px',
        color: '#ffffff'
    },
    divTry: {
        fontSize: '20px',
        color: '#FFD76A'
    },
    textField: {
        // width:'100%',
        backgroundColor: '#fff',
        margin: '18px 0px',
        '& .MuiOutlinedInput-inputMarginDense': {
            padding: '15px 10px'
        },
        '&:hover': {
            border: 'unset'
        },
        '& .MuiOutlinedInput:focus': {
            outline: 0
        },
    },
    successIcon: {
        width: '25%',
        paddingTop: '35px',
        paddingBottom: '35px',
        [theme.breakpoints.only('sm')]: {
            paddingTop: '30px',
            paddingBottom: '30px',
        },
        [theme.breakpoints.only('xs')]: {
            paddingTop: '30px',
            paddingBottom: '30px',
        },
    },
    divIcons: {
        textAlign: 'center'
    },
    inputProps: {
        step: 300,
    },
    pasteSection: {
        // height: '400px',
        overflow: 'auto',
        marginTop: '18px'
    },
    pasteTextField: {
        width: '100%',
        minHeight: '325px',
        backgroundColor: '#BABABA15',
        borderColor: '#7D7D7D15'
    },
    divAccept: {
        fontSize: '18px',
        color: '#7D7D7D'
    },
    divTerm: {
        fontSize: '18px',
        color: '#7D7D7D',
        fontWeight: 'bold'
    },
    radioStyle: {
        marginTop: '20px',
        color: '#7D7D7D',
        '& .makeStyles-checkedIcon-39': {
            backgroundColor: '#7D7D7D'
        }
    },
    stellarktcIcon: {
        width: '25%',
        paddingTop: '35px',
        paddingBottom: '35px',
        [theme.breakpoints.down('sm')]: {
            paddingTop: '15px',
            paddingBottom: '15px',
        },
    },
    divstellarktcIcon: {
        textAlign: 'center',
        '& .makeStyles-stellarktcIcon-23': {
            width: '35%',
            [theme.breakpoints.only('xs')]: {
                width: '70%',
            },
            [theme.breakpoints.only('sm')]: {
                width: '50%',
            },
            [theme.breakpoints.only('md')]: {
                width: '50%',
            },
        }
    },
    divtextswap: {
        fontSize: '30px',
        color: '#7D7D7D',
    },
    divswapping: {
        fontSize: '20px',
        color: '#7D7D7D',
        padding: '30px 0px 0px',
    },
    divsixonstellar: {
        fontSize: '20px',
        color: '#7D7D7D',
    },
    divtextmemo: {
        fontSize: '20px',
        color: '#7D7D7D',
        [theme.breakpoints.only('sm')]: {
            paddingTop: '10px',
        },
        [theme.breakpoints.only('xs')]: {
            paddingTop: '10px',
            fontSize: '16px',
        },
    },
    divtextremark: {
        fontSize: '20px',
        color: '#7D7D7D',
        paddingTop: '25px',
        [theme.breakpoints.only('sm')]: {
            paddingTop: '10px',
        },
        [theme.breakpoints.only('xs')]: {
            paddingTop: '10px',
            fontSize: '16px',
        },
    },
    gridStellar: {
        display: 'grid',
        alignItems: 'center',
        color: '#7D7D7D',
    },
    gridItemStellar: {
        paddingBottom: '20px',
        textAlign: 'center',
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.only('sm')]: {
            fontSize: '12px'
        },
        [theme.breakpoints.only('xs')]: {
            fontSize: '8px',
            paddingBottom: '10px',
        },
    },
    stellarIcon: {
        width: '3%',
        paddingLeft: '6px',
        [theme.breakpoints.only('xs')]: {
            width: '6%',
        },
    },
    gridKlaytn: {
        alignItems: 'center',
        color: '#7D7D7D',
    },
    klaytnIcon: {
        width: '6%',
        paddingLeft: '6px'
    },
    gridExchange: {
        margin: '20px 0px',
        alignItems: 'center'
    },
    exchangeImg: {
        width: '70%',
    },
    exchangeImg1: {
        width: '50%',
    },
    paperStellar: {
        backgroundColor: '#EDEDEF',
        boxShadow: 'unset',
        textAlign: 'center',
        borderTopRightRadius: 'unset',
        borderBottomRightRadius: 'unset',
    },
    paperKlaytn: {
        backgroundColor: '#EDEDEF',
        boxShadow: 'unset',
        alignItems: 'center',
        display: 'flex',
        height: '197px',
        textAlign: 'center',
        borderTopLeftRadius: 'unset',
        borderBottomLeftRadius: 'unset',
        borderLeft: '1px solid #7D7D7D',
        [theme.breakpoints.only('md')]: {
            height: '158px',
        },
        [theme.breakpoints.only('sm')]: {
            height: '135px',
        },
        [theme.breakpoints.only('xs')]: {
            height: '96px',
        },
    },
    btnCopy: {
        width: '80%',
        padding: '0px',
        cursor: 'pointer',
    },
    margin: {
        margin: '20px 0px',
        border: 'unset',
        '& .Mui-focused': {
            border: 'unset'
        },
        '& .MuiOutlinedInput-adornedEnd': {
            paddingRight: "0px"
        },
        '& .MuiOutlinedInput-input': {
            padding: '14px',
            [theme.breakpoints.only('xs')]: {
                padding: '10px',
            },
        }
    },
    marginInput: {
        margin: '20px 0px',
        backgroundColor: '#fff',
        border: 'unset',
        '& .Mui-focused': {
            border: 'unset'
        },
        '& .MuiOutlinedInput-adornedEnd': {
            paddingRight: "0px"
        },
        '& .MuiOutlinedInput-input': {
            padding: '14px',
            [theme.breakpoints.only('xs')]: {
                padding: '10px',
            },
        }
    },
}))

export default tranferStyle