/*
 * HomePage Messages
 *
 * This contains all the text for the HomePage container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.HomePage';

export default defineMessages({
  thai: {
    id: `${scope}.thai`,
    defaultMessage: 'ไทย',
  },
  eng: {
    id: `${scope}.eng`,
    defaultMessage: 'English',
  },
  korea: {
    id: `${scope}.korea`,
    defaultMessage: '한글',
  },
  swap: {
    id: `${scope}.swap`,
    defaultMessage: 'Swap',
  },
  checkswap: {
    id: `${scope}.checkswap`,
    defaultMessage: 'Check Swap Status',
  },
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Here are the 3 reasons SIX Network migrates into Klaytn blockchain network.',
  },
  detail1:{
    id: `${scope}.detail1`,
    defaultMessage: 'Klaytn’s vision to trigger the mass adoption in blockchain space aligns with SIX Network’s.',
  },
  detail2:{
    id: `${scope}.detail2`,
    defaultMessage: 'Technology from Klaytn enables us to not only provide the best payment service but also the best media management provider.',
  },
  detail3:{
    id: `${scope}.detail3`,
    defaultMessage: 'SIX Network’s current and potential user range matches with Kakao’s current and potential user range.',
  },
  titleModal1:{
    id: `${scope}.titleModal1`,
    defaultMessage: 'Important Notes',
  },
  titleModal2:{
    id: `${scope}.titleModal2`,
    defaultMessage: '(Please read carefully - DO NOT SKIP this page!)',
  },
  detailModal1:{
    id: `${scope}.detailModal1`,
    defaultMessage: 'If you wish to swap SIX-Stellar to SIX-KCT, please make sure you have both Klaytn’s public and private address on hand. You can generate a new wallet address on Klaytn in either https://scope.klaytn.com/ or KLIP wallet on Kakao. Please DO NOT LOSE THE PRIVATE KEY. SIX Network is not responsible for the loss of your own private key.',
  },
  detailModal2:{
    id: `${scope}.detailModal2`,
    defaultMessage: 'Before the end of migration, SIX tokens are on both Stellar Lumen and Klaytn. Any time that you wish to make SIX transactions, please make sure that the wallet address is on the same blockchain as the tokens you are sending to.',
  },
  detailModal3:{
    id: `${scope}.detailModal3`,
    defaultMessage: 'Please be REMINDED again that once the swap is initiated, you cannot swap the tokens on Klaytn back to Stellar Lumen.',
  },
  btnBack:{
    id: `${scope}.btnBack`,
    defaultMessage: 'Back'
  },
  btnDone:{
    id: `${scope}.btnDone`,
    defaultMessage: 'Done'
  }
});
