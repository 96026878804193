import React, { memo, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import messages from "./messages";
import { compose } from "redux";
// import { Link } from 'react-router-dom';
import Header from "../../components/Header";
import PropTypes from "prop-types";
// import { changeLang } from "../../actions";
import { connect } from "react-redux";
import { informationSelected } from "../../actions";
import { verifySelected } from "../../actions";
import { swapSelected } from "../../actions";
import _ from "lodash";

// Firebase App (the core Firebase SDK) is always required and must be listed first
import * as firebase from "firebase/app";
// If you enabled Analytics in your project, add the Firebase SDK for Analytics
import "firebase/analytics";

import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import CloseIcon from "@material-ui/icons/Close";

import swapStyle from "./swapStyle";
import SwapImg from "../../assets/img/swapImg.png";
import PinInput from "react-pin-input";
import { klaytnService } from "../../services";
import Alert from "@material-ui/lab/Alert";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";

function Swap(props) {
  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    firebase.analytics().setCurrentScreen("SwapScreen");
    props.history.push("/");
  });

  const classes = swapStyle();
  const [value, setValue] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [klaytn, setKlaytn] = React.useState("");
  const [colors, setColors] = React.useState(true);
  const [openInputNotification, setOpenInputNotification] = React.useState(
    false
  );
  const [error, setError] = React.useState("");
  const [disable, setDisable] = React.useState(false);

  const handleOpen = () => {
    let txid = _.get(props.verify, "txid");
    let mobile = _.get(props.verify, "mobile");
    try {
      firebase.analytics().logEvent("click_verify_klaytn");

      klaytnService
        .createData(txid, mobile, klaytn)
        .then((resp) => {
          if (resp.success === true) {
            firebase
              .analytics()
              .logEvent("verify_klaytn_response", { success: true });

            setOpen(true);
            setDisable(true);
            console.log("resp", resp);
          } else {
            firebase
              .analytics()
              .logEvent("verify_klaytn_response", { success: false });

            setOpenInputNotification(true);
            setError(resp.error_message);
            console.log("resp", resp.error_message);
          }
        })
        .catch((error) => {
          console.log("resp", error);
        });
    } catch (error) {}
  };

  const handleClose = () => {
    setOpen(false);
    setDisable(false);
  };
  const handleChange = (event) => {
    let txid = _.get(props.verify, "txid");
    let mobile = _.get(props.verify, "mobile");
    setColors(true);
    setValue(event);
    if (event.length === 4) {
      if (event === klaytn.slice(-4).toUpperCase()) {
        firebase.analytics().logEvent("input_klaytn_addr_confirm");
        let datas = {
          txid,
          mobile,
          klaytn,
        };
        props.onSwap(datas);

        props.history.push("/conditions");
      } else {
        firebase.analytics().logEvent("klaytn_addr_confirm_invalid");

        setValue("");
        setColors(false);
      }
    }
  };
  const handleChangeKlaytn = (value) => {
    setKlaytn(value);
  };
  const onCloseNotification = (value) => {
    if (value === "openInputNotification") {
      setOpenInputNotification(false);
    }
  };

  return (
    <div className={classes.root}>
      <Header history={props.history} />
      <Container maxWidth="xl" className={classes.container}>
        <div className={classes.title}>
          <span>
            <FormattedMessage {...messages.title1} />
          </span>
        </div>
        <div className={classes.title2}>
          <span>
            <FormattedMessage {...messages.title2} />
          </span>
        </div>

        <TextField
          id="outlined-search"
          margin="dense"
          variant="outlined"
          fullWidth
          className={classes.textField}
          defaultValue={klaytn}
          onChange={(event) => {
            handleChangeKlaytn(event.target.value);
          }}
        />
        <Collapse in={openInputNotification} style={{ width: "100%" }}>
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => onCloseNotification("openInputNotification")}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            <span>{error}</span>
          </Alert>
        </Collapse>
        <div className={classes.divBtn}>
          <Button
            disabled={disable}
            onClick={handleOpen}
            className={classes.btnVerify}
          >
            <FormattedMessage {...messages.btnVerify} />
          </Button>
          <Modal
            aria-labelledby="title"
            aria-describedby="description"
            className={classes.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={open}>
              <div className={classes.paperModal}>
                <div className={classes.closeIcon}>
                  <CloseIcon onClick={handleClose} />
                </div>
                <div className={classes.divImageNote}>
                  <img
                    src={SwapImg}
                    alt="SwapImg"
                    className={classes.imporNote}
                  ></img>
                </div>
                <span className={classes.title1}>
                  <FormattedMessage {...messages.warning} />
                </span>
                <div className={classes.text1}>
                  <FormattedMessage {...messages.detailWarning1} />
                </div>
                <div className={classes.text2}>
                  <FormattedMessage {...messages.detailWarning2} />
                </div>
                <TextField
                  id="outlined-search"
                  disabled
                  margin="dense"
                  variant="outlined"
                  value={klaytn}
                  fullWidth
                  className={classes.inputModal}
                />
                <div className={classes.divImageNote}>
                  <FormattedMessage {...messages.pleasetype} />
                  <span style={{ color: "#484848", fontWeight: "bold" }}>
                    <FormattedMessage {...messages.lastdigit} />
                  </span>
                </div>
                <div style={{ textAlign: "center" }}>
                  <PinInput
                    length={4}
                    initialValue=""
                    focus={true}
                    value={value}
                    onChange={(value, index) => {
                      handleChange(value);
                    }}
                    type="text"
                    //ignoreCase={true}
                    style={{ padding: "10px" }}
                    inputStyle={{
                      borderBottomColor: "#7D7D7D",
                      borderLeftColor: "#ffffff",
                      borderRightColor: "#ffffff",
                      borderTopColor: "#ffffff",
                      borderWidth: 1,
                      color: colors ? "#000000" : "red",
                      fontWeight: "bold",
                      fontSize: 20,
                    }}
                    inputFocusStyle={{
                      borderLeftColor: "#ffffff",
                      borderRightColor: "#ffffff",
                      borderBottomColor: "#7D7D7D",
                    }}
                    onComplete={(event, index) => {}}
                  />
                </div>
              </div>
            </Fade>
          </Modal>
        </div>
        {props.locale === "en" ? (
          <div className={classes.howto}>
            <Button
              href="https://medium.com/@copywriter_sixnetwork/create-a-new-klaytn-account-f72b21cb902e"
              target="_blank"
              className={classes.linkKlaytn}
            >
              <FormattedMessage {...messages.howto} />
            </Button>
          </div>
        ) : (
          <div className={classes.howto}>
            <Button
              href="https://medium.com/@copywriter_sixnetwork/%EC%83%88-%EA%B3%84%EC%A0%95-%EC%83%9D%EC%84%B1%ED%95%98%EA%B8%B0-5a93e0139bb8"
              target="_blank"
              className={classes.linkKlaytn}
            >
              <FormattedMessage {...messages.howto} />
            </Button>
          </div>
        )}
      </Container>
    </div>
  );
}

Swap.propTypes = {
  locale: PropTypes.string,
  data: PropTypes.string,
  onData: PropTypes.func,
  onVerify: PropTypes.func,
  onSwap: PropTypes.func,
};

const mapStateToProps = (state, ownProps) => {
  return {
    locale: state.language.locale,
    information: state.information.data,
    verify: state.verify.verify,
    swap: state.swap.swap,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    //   onLocaleToggle: (key) => dispatch(changeLang(key)),
    onData: (key) => dispatch(informationSelected(key)),
    onVerify: (key) => dispatch(verifySelected(key)),
    onSwap: (key) => dispatch(swapSelected(key)),
  };
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(Swap);
