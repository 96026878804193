import React, { Component } from "react";
import PropTypes from "prop-types";
import { Provider, connect } from "react-redux";
import { IntlProvider } from "react-intl";

import { translationMessages } from "./i18n";
import AppRouter from "./AppRouter";

class App extends Component {
  render() {
    const { store, history, locale } = this.props;

    return (
      <IntlProvider
        locale={locale}
        key={locale}
        messages={translationMessages[locale]}
      >
        <Provider store={store}>
          <AppRouter store={store} history={history} />
        </Provider>
      </IntlProvider>
    );
  }
}

App.propTypes = {
  store: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {
    locale: state.language.locale,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    // currentUser: () => dispatch(userActions.currentUser()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
