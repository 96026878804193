import React, { memo, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// import {withStyles} from "@material-ui/core/styles"
import { FormattedMessage } from "react-intl";
import messages from "./messages";
import { issueService } from "../../services";
//import * as moment from "moment";
import _ from "lodash";
import { informationSelected } from "../../actions";

// Firebase App (the core Firebase SDK) is always required and must be listed first
import * as firebase from "firebase/app";
// If you enabled Analytics in your project, add the Firebase SDK for Analytics
import "firebase/analytics";

import Alert from "@material-ui/lab/Alert";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Button from "@material-ui/core/Button";

import { compose } from "redux";
import Header from "../../components/Header";

import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";

import informationStyle from "./informationStyle";

// import Thai from "../../assets/icons/thai.png";
// import Korea from "../../assets/icons/korea.png";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

function Information(props) {
  const { locale } = props;

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    firebase.analytics().setCurrentScreen("InputInfoScreen");
    props.history.push("/");
    console.log("information", props);
    if (phone.length < 1) {
      setPhone("");
    }
  }, [props]);

  const classes = informationStyle();
  const [name, setName] = React.useState("");
  const [lastname, setLastname] = React.useState("");
  let [mobile, setMobile] = React.useState("");
  const [openInputNotification, setOpenInputNotification] = React.useState(
    false
  );
  //const [lang, setLang] = React.useState("th");
  const [langtext, setLangtext] = React.useState("+66");
  const [phone, setPhone] = React.useState("66");
  console.log("PHONE", phone);
  // const handleChange = (event) => {
  //   //setLang()
  //   if (event.target.value === "th") {
  //     setLangtext("+66");
  //   } else if (event.target.value === "ko") {
  //     setLangtext("+82");
  //   }
  //   console.log("event", event.target.value);
  // };
  const issue = () => {
    console.log("-----------------------", "+", phone, mobile);
    if (name === "" || lastname === "" || mobile === "" || phone === "") {
      setOpenInputNotification(true);
    } else {
      firebase
        .analytics()
        .logEvent("click_issue_otp", { country_prefix: langtext });

      try {
        issueService
          .updateData(
            "new",
            "Verify phone number",
            "+" + phone + mobile,
            name,
            lastname
          )
          .then((resp) => {
            if (resp.success === true) {
              let data = _.get(resp, "data");
              let ref_no = _.get(data, "ref_no");
              let tx_id = _.get(data, "tx_id");
              let mobiles = "+" + phone + mobile;
              console.log("==================", mobiles);
              let datas = {
                tx_id,
                mobiles,
                ref_no,
                name,
                lastname,
              };
              props.onData(datas);
              props.history.push("/verify");
              //this.props.informationSelected(tx_id)
              console.log("resp", resp);
            } else {
              console.log("resp", resp.error);
            }
          })
          .catch((error) => {
            console.log("resp", error);
          });
      } catch (error) {}
    }
  };

  const onCloseNotification = (value) => {
    if (value === "openInputNotification") {
      setOpenInputNotification(false);
    }
  };

  const handleChangeName = (value) => {
    setName(value);
  };
  const handleChangeLastname = (value) => {
    setLastname(value);
  };
  const handleChangeMobile = (value) => {
    // if (value.length === 10) {
    // setMobile(value);
    // }
    var newText = "";
    var numbers = "0123456789";
    if (value.length < 1) {
      setMobile("");
    }
    for (var i = 0; i < value.length; i++) {
      if (numbers.indexOf(value[i]) > -1) {
        newText = newText + value[i];
      }
      setMobile(newText);
    }
  };

  return (
    <div className={classes.root}>
      <Header locale={locale} />
      <Container maxWidth="xl" className={classes.container}>
        <div className={classes.title}>
          <FormattedMessage {...messages.title} />
        </div>
        <Paper className={classes.paper}>
          <div className={classes.divText}>
            <TextField
              id="name"
              label={<FormattedMessage {...messages.name} />}
              className={classes.textName}
              defaultValue={name}
              onChange={(event) => {
                handleChangeName(event.target.value);
              }}
            />
          </div>
          <Divider />
          <div className={classes.divText}>
            <TextField
              id="lastname"
              label={<FormattedMessage {...messages.lastname} />}
              className={classes.textName}
              defaultValue={lastname}
              onChange={(event) => {
                handleChangeLastname(event.target.value);
              }}
            />
          </div>
          <Divider />
          <div className={classes.divTopic}>
            <FormattedMessage {...messages.mobilenumber} />
          </div>
          <div className={classes.divMobileNumber}>
            <PhoneInput
              country={"th"}
              value={phone}
              onChange={(phone) => setPhone(phone)}
              inputProps={{
                maxLength: "2",
              }}
              disableSearchIcon
            />
            <TextField
              id="mobile-number"
              autoFocus={true}
              inputProps={{
                autoFocus: true,
              }}
              className={classes.textStyle}
              value={mobile}
              onChange={(event) => {
                handleChangeMobile(event.target.value);
              }}
            />
          </div>
          <Collapse in={openInputNotification} style={{ width: "100%" }}>
            <Alert
              severity="error"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => onCloseNotification("openInputNotification")}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              <FormattedMessage {...messages.alert} />
            </Alert>
          </Collapse>
        </Paper>
        <div className={classes.divBtnNext}>
          <Button className={classes.btnNext} onClick={issue}>
            <FormattedMessage {...messages.btnNext} />
          </Button>
        </div>
      </Container>
    </div>
  );
}

Information.propTypes = {
  locale: PropTypes.string,
  data: PropTypes.string,
  onData: PropTypes.func,
};

const mapStateToProps = (state, ownProps) => {
  return {
    locale: state.language.locale,
    information: state.information.information,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    //   onLocaleToggle: (key) => dispatch(changeLang(key)),
    onData: (key) => dispatch(informationSelected(key)),
  };
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect, memo)(Information);
