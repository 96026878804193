import React, { memo } from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import messages from "./messages";
import { compose } from "redux";
import { Link } from "react-router-dom";
import { changeLang } from "../../actions";

import Container from "@material-ui/core/Container";
import AppBar from "@material-ui/core/AppBar";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import Divider from "@material-ui/core/Divider";
import Hidden from "@material-ui/core/Hidden";
import TextField from "@material-ui/core/TextField";
import headerStyle from "./headerStyle";

import Logo from "../../assets/img/logo.png";

//import Thai from "../../assets/icons/thai.png";
import Eng from "../../assets/icons/eng.png";
import Korea from "../../assets/icons/korea.png";

function Header(props) {
  const { locale } = props;

  const classes = headerStyle();
  // const [anchorEl, setAnchorEl] = React.useState("English");

  const handleChange = (event) => {
    // setAnchorEl(event.target.value);
    props.onLocaleToggle(event.target.value);
  };

  // const handleClose = () => {
  //     setOpen(false);
  // };
  return (
    <AppBar position="static" className={classes.appBar}>
      <Container maxWidth="xl" className={classes.container}>
        <Grid container className={classes.gridCon}>
          <Grid item lg={2} md={2} sm={4} xs={6}>
            <Link to="/">
              <img src={Logo} alt="Logo" className={classes.logo}></img>
            </Link>
          </Grid>
          <Hidden smDown>
            <Grid item lg={2} md={2} sm={4}>
              {props.page === "CheckSwap" ? (
                <Link to="/info">
                  <span className={classes.checkLink}>
                    <FormattedMessage {...messages.header1} />
                  </span>
                </Link>
              ) : (
                  <Link to="/check-swap">
                    <span className={classes.checkLink}>
                      <FormattedMessage {...messages.header2} />
                    </span>
                  </Link>
                )}
            </Grid>
          </Hidden>
          <Grid item lg={8} md={8} sm={4} xs={6}>
            <div className={classes.divForm}>
              <TextField
                id="select-locale"
                select
                value={locale}
                onChange={handleChange}
                className={classes.divMenu}
              >
                <MenuItem value="en">
                  <img
                    src={Eng}
                    alt="Eng"
                    className={classes.flagIcons}
                  ></img>
                  <FormattedMessage {...messages.eng} />
                </MenuItem>
                <Divider />
                <MenuItem value="ko">
                  <img
                    src={Korea}
                    alt="Korea"
                    className={classes.flagIcons}
                  ></img>
                  <FormattedMessage {...messages.korea} />
                </MenuItem>
              </TextField>
            </div>
          </Grid>
        </Grid>
      </Container>
    </AppBar>
  );
}

Header.propTypes = {
  onLocaleToggle: PropTypes.func,
  locale: PropTypes.string,
};

const mapStateToProps = (state, ownProps) => {
  return {
    locale: state.language.locale,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onLocaleToggle: (key) => dispatch(changeLang(key)),
  };
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(Header);
