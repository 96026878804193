/*
 * Verify Messages
 *
 * This contains all the text for the Verify container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.Verify';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Please type your verification code',
  },
  refno:{
    id: `${scope}.refno`,
    defaultMessage: 'Ref No.'
  },
  sms:{
    id: `${scope}.sms`,
    defaultMessage: 'Didn’t receive an SMS?'
  },
  tryagain:{
    id: `${scope}.tryagain`,
    defaultMessage: 'Try again'
  },
 
});
