import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import information from "./information.reducer"
import verify from "./verify.reducer";
import swap from "./swap.reducer";
import conditions from "./conditions.reducer";
import tranfer from "./tranfer.reducer";
import confirmation from "./confirmation.reducer";
import language from "./language.reducer";

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    language: language,
    information: information,
    verify: verify,
    swap: swap,
    conditions: conditions,
    tranfer:tranfer,
    confirmation: confirmation
  });
