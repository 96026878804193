import React, { Component } from "react";
import { Route, Switch } from "react-router";
import { ConnectedRouter } from "connected-react-router";

import HomePage from "./containers/HomePage/index";
import Information from "./containers/Information/index";
import Verify from "./containers/Verify/index";
import Swap from "./containers/Swap/index";
import CheckSwapStatus from "./containers/CheckSwapStatus/index";
import Conditions from "./containers/Conditions/index";
import Tranfer from "./containers/Tranfer/index";
import Confirmation from "./containers/Confirmation/index";
import ShowProcess from "./containers/ShowProcess";

class AppRouter extends Component {
  render() {
    const { history } = this.props;
    return (
      <ConnectedRouter history={history}>
        <Switch>
          <Route path="/" exact component={HomePage} />
          <Route path="/info" exact component={Information} />
          <Route path="/verify" exact component={Verify} />
          <Route path="/swap" exact component={Swap} />
          <Route path="/check-swap" exact component={CheckSwapStatus} />
          <Route path="/conditions" exact component={Conditions} />
          <Route path="/tranfer" exact component={Tranfer} />
          <Route path="/confirmation" exact component={Confirmation} />
          <Route path="/showprocess" exact component={ShowProcess} />
        </Switch>
      </ConnectedRouter>
    );
  }
}

export default AppRouter;
