import { API } from 'aws-amplify'
// import moment from 'moment'

const getTicket = ticket => {
    return API.get("*", `/ticket/${ticket}`, {});
};


export const ticketService = {
    getTicket,
}
