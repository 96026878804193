import { makeStyles } from '@material-ui/core/styles';
const swapStyle = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: '#F5F5F8',
        //height: '100vh',
        [theme.breakpoints.between('xs', 'md')]: {
            height: '100vh',
        },
    },
    container: {
        padding: "100px 60px 60px 60px",
        [theme.breakpoints.only('xs')]: {
            padding: "100px 20px 40px 20px",
        },
        [theme.breakpoints.only('sm')]: {
            padding: "40px 40px 40px 40px",
        },
    },
    title:{
        fontSize: '30px', 
        color: '#7D7D7D' 
    },
    title2:{
        fontSize: '20px', 
        color: '#7D7D7D', 
        marginTop: '40px',
        [theme.breakpoints.only('xs')]: {
            marginTop: '20px',
            fontSize: '16px', 
        },
    },
    textField:{
        backgroundColor:'#fff',
        margin:'18px 0px',
        '& .MuiOutlinedInput-inputMarginDense':{
            padding:'15px 10px'
        },
        '&:hover':{
            border:'unset'
        },
        '& .MuiOutlinedInput:focus':{
            outline:0
        },
        [theme.breakpoints.only('xs')]: {
            margin:'28px 0px 6px'
        },
    },
    divBtn:{
        textAlign:'center',
        width:'100%',
        paddingTop:'30px',
        [theme.breakpoints.only('xs')]: {
            paddingTop:'20px',
        },
    },
    btnVerify:{
        backgroundColor:"#4A00E0",
        color:'#fff',
        width:'25%',
        fontSize:'24px',
        fontWeight:'bold',
        borderRadius:'35px',
        textTransform:'capitalize',
        '&:hover':{
            backgroundColor:"#4A00E0",
        },
        [theme.breakpoints.only('xs')]: {
            width:'100%',
            fontSize:'20px',
        },
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paperModal: {
        backgroundColor: theme.palette.background.paper,
        borderRadius:'6px',
        boxShadow: theme.shadows[5],
        padding: "30px 15px",
        width:'50%',
        outline: 0,
        [theme.breakpoints.only('xs')]: {
            width:'100%',
        },
    },
    title1:{
        fontWeight:'bold',
        color:'#484848',
        paddingLeft:'20px'
    },
    // title2:{
    //     fontSize:'20px',
    //     color:'#7D7D7D',
    //     padding:'30px 0px 10px'
    // },
    text1:{
        fontSize:'16px',
        color:'#484848',
        paddingLeft:'20px',
        padding:'20px 0px 10px'
    },
    text2:{
        fontSize:'16px',
        color:'#484848',
        paddingLeft:'20px',
        padding:'10px 0px'
    },
    divImageNote:{
        textAlign:'center'
    },
    imporNote:{
        width:'50%',
        paddingBottom:'30px'
    },
    closeIcon:{
        textAlign:'end',
        cursor:'pointer'
    },
    inputModal:{
        backgroundColor:'#fff',
        margin:'18px 15px',
        width:'95%',
        '& .MuiOutlinedInput-inputMarginDense':{
            padding:'15px 10px'
        },
        '&:hover':{
            border:'unset'
        },
        '& .MuiOutlinedInput:focus':{
            outline:0
        },
    },
    howto:{
        paddingTop: '290px',
        [theme.breakpoints.only('xs')]: {
            paddingTop: '205px',
        },
    },
    linkKlaytn:{
        textTransform:'capitalize',
        color:'#8E2DE2',
        fontSize:'16px',
        fontWeight:'unset',
        textDecoration:'underline',
        '&:hover':{
            backgroundColor:'transparent',
            textDecoration:'underline',
        }
    }
    
}))

export default swapStyle