import {informationConstants } from '../constants'


export const informationSelected = (information) => {
    return async (dispatch) => {
        dispatch({ type : informationConstants.INFORMATION_SELECTED, information})
    }
}

// export const informationActions = {
//      informationSelected
// }