import { API } from 'aws-amplify'
// import moment from 'moment'

const createData = (
    tx_id,
    phone_number,
    klaytn_addr,
) => {
    return API.post('*', '/tx', {
        // headers: {
        //     'x-six-authorization': token,
        // },
        body: {
            tx_id: tx_id,
            phone_number: phone_number,
            klaytn_addr: klaytn_addr,
        },
    })
}

export const klaytnService = {
    createData,
}
