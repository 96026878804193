import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import configureStore, { history } from "./configureStore";
import registerServiceWorker from "./serviceWorker";

// Firebase App (the core Firebase SDK) is always required and must be listed first
import * as firebase from "firebase/app";

// If you enabled Analytics in your project, add the Firebase SDK for Analytics
import "firebase/analytics";

// Add the Firebase products that you want to use
import "firebase/auth";
import "firebase/firestore";

import { amplifyConfig } from "./config/amplify.config";
import Amplify from "aws-amplify";

const store = configureStore(/* provide initial state if any */);

// Amplify config
Amplify.configure(amplifyConfig);

// Firebase config
const firebaseConfig = {
  apiKey: "AIzaSyBUqV0pSQi_vKJjQWi-X-aIQ2F4ZOZsybI",
  authDomain: "six-swap.firebaseapp.com",
  databaseURL: "https://six-swap.firebaseio.com",
  projectId: "six-swap",
  storageBucket: "six-swap.appspot.com",
  messagingSenderId: "218768512171",
  appId: "1:218768512171:web:76ef2c27e19967194c93ee",
  measurementId: "G-18CXEFK7GR",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

ReactDOM.render(
  <App store={store} history={history} />,
  document.getElementById("root")
);

registerServiceWorker();
