import { API } from 'aws-amplify'
// import moment from 'moment'

const updateData = (
    action,
    sms_topic_desc,
    phone_number,
    firstname,
    lastname,
) => {
    return API.put('*', '/otp/issue', {
        // headers: {
        //     'x-six-authorization': token,
        // },
        body: {
            action: action,
            sms_topic_desc: sms_topic_desc,
            phone_number: phone_number,
            firstname: firstname,
            lastname: lastname,
        },
    })
}

export const issueService = {
    updateData,
}
