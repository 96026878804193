import { makeStyles } from '@material-ui/core/styles';
const headerStyle = makeStyles(theme => ({
    appBar: {
        backgroundColor: "#330798",
        position: "fixed"
    },
    container: {
        padding: "0px 60px",
        [theme.breakpoints.only('xs')]: {
            padding: "0px 20px",
        },
    },
    gridCon: {
        justifyContent: "space-between",
        alignItems: 'center',
        height: '70px'
    },
    logo: {
        width: '55%',
        [theme.breakpoints.only('xs')]: {
            width: '65%',
        },
    },
    checkLink: {
        color: '#FFD76A',
        display: 'inline-block'
    },
    divForm: {
        textAlign: 'end',
        [theme.breakpoints.only('xs')]: {
            padding: "17px 0px 0px",
        },
        [theme.breakpoints.only('sm')]: {
            padding: "18px 0px 0px",
        },
    },
    formControl: {
        width: '130px',
        '& .MuiSelect-select.MuiSelect-select': {
            textAlign: 'center',
            color: '#fff',
        },
        '& .MuiInput-underline:before': {
            content: 'unset'
        },
        [theme.breakpoints.between('xs', 'sm')]: {
            marginBottom: '20px',
        },
    },
    divMenu: {
        '& .MuiList-padding': {
            paddingTop: '0px',
            paddingBottom: '0px'
        },
        '& .MuiInput-underline:before': {
            content: 'unset'
        },
        '& .MuiSelect-select.MuiSelect-select': {
            alignItems: 'center',
            display: 'flex',
            fontSize: '14px',
            color: '#fff',
        }
    },
    flagIcons: {
        width: '28px',
        height: '18px',
        paddingRight: '4px'
    },
}))

export default headerStyle